import { Title } from "@vshp/ui-components";
import { Link } from "react-router-dom";

const PageContentButton = ({ title, text, button }) => {
  return (
    <>
      {title !== null && title.length > 0 && <Title type="h2">{title}</Title>}
      {text !== null && <div dangerouslySetInnerHTML={{ __html: text }}></div>}
      <div className="wysiwyg__button-block-container">
        <Link className="btn btn_outline" to={button.url}>
          {button.title}
        </Link>
      </div>
    </>
  );
};

export default PageContentButton;
