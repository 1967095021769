import axios from "axios";
import { FACULTIES, FACULTIES_ARE_LOADING } from "../constants/faculties";
import { API_URL } from "../config/config";
import { SOURCE } from "../constants/global";

export function fetchFaculties(locale) {
  return (dispatch) => {
    dispatch(facultiesAreLoading(true));

    const config = { params: { locale: locale, source: SOURCE } };

    axios
      .get(`${API_URL}faculties`, config)
      .then((response) => {
        dispatch(facultiesSuccess(response.data.data));
        dispatch(facultiesAreLoading(false));

        return response;
      })
      .catch((e) => console.log("error loading faculties", e));
  };
}

function facultiesAreLoading(bool) {
  return {
    type: FACULTIES_ARE_LOADING,
    bool,
  };
}

function facultiesSuccess(data) {
  return {
    type: FACULTIES,
    data,
  };
}
