import { Title } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";

function How() {
  const { t } = useTranslation();

  return (
    <div className="page__block">
      <Title type="h2" data-key="home.heading.how_do_we_teach">{t("home.heading.how_do_we_teach")}</Title>
      <div className="page__text wysiwyg">
        <div className="columns" data-key="home.how_text" dangerouslySetInnerHTML={{ __html: t("home.how_text") }}></div>
      </div>
    </div>
  );
}

export default How;
