import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, createSearchParams } from "react-router-dom";

import { fetchPartners } from "../../actions/partners";
import { Loading } from "../utils/loading";
import MainLayout from "../layout";
import Offer from "../shared/Offer";
import i18n from "i18next";
import { Title } from "@vshp/ui-components";
import partnersListBg from "../../images/partners.jpg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { languagePathUrl } from "../utils/languagePathUrl";

function PartnerPage() {
  const dispatch = useDispatch();
  const { currentLanguage } = useSelector((state) => state.global);
  let locale = i18n.language;
  const { partners, partnersAreLoading } = useSelector((state) => state.partners);
  let content = null;
  let title = null;
  const { t } = useTranslation();

  const { slug } = useParams();

  useEffect(() => {
    if (partners.length < 1) {
      dispatch(fetchPartners(locale));
    }
  }, [dispatch, locale]);

  if (partnersAreLoading) {
    content = <Loading contentPreloader />;
    title = {
      caption: t("ui.loading"),
    };
    return <MainLayout title={title}>{content}</MainLayout>;
  }

  const partner = partners.find((item) => item.attributes.slug === slug);

  title = {
    caption: `${t("ui.menu_item_partners")} ${partner.attributes.title}`,
  };

  return (
    <MainLayout title={title}>
      <div className="page__header">
        <img className="page__header-cover" src={partnersListBg} alt="" />
        <div className="page__header-body page__header-body_center">
          <Title decor={false} className="page__header-title" data-key="ui.menu_item_partners">
            {t("ui.menu_item_partners")}
          </Title>
          <div className="page__header-subtitle">{partner.attributes.title}</div>
          {partner.attributes.tagline.length > 0 && <div className="page__header-intro" dangerouslySetInnerHTML={{ __html: partner.attributes.tagline }}></div>}
        </div>
      </div>
      <div className="page__blocks partner">
        <div className="page__block">
          <div className="page__block-body">
            <div className="partner__inner">
              <div className="partner__col">
                <div className="partner__logo">
                  <img src={partner.attributes.logo} alt={partner.attributes.title} />
                </div>
              </div>
              <div className="partner__col">
                <div className="partner__text wysiwyg" dangerouslySetInnerHTML={{ __html: partner.attributes.description }}></div>
                {partner.attributes.programs > 0 && (
                  <div className="partner__btn-wrap">
                    <Link to={`${languagePathUrl(currentLanguage)}/programs?${createSearchParams({ "category_id[]": partner.attributes["category-id"] })}`} data-key="programs.select" className="btn btn_outline">
                      {t("programs.select")}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Offer />
      </div>
    </MainLayout>
  );
}

export default PartnerPage;
