function ReviewCard({ review }) {
  return (
    <div className="review page__block page__block_border">
      <div className="page__block-body">
        <div className="review__item">
          <div className="review__photo">
            <img src={review.photo.preview} alt={review.name} />
          </div>
          <div className="review__col">
            <div className="review__header">
              <div className="review__name t-h3">{review.name}</div>
              <div className="review__post">{review.description}</div>
            </div>
            <div className="review__text wysiwyg" dangerouslySetInnerHTML={{ __html: review.content }}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewCard;
