import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { fetchProgramsPopular } from "../../actions/programs";
import { Loading } from "../utils/loading";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

import { renderProgramCard } from "../utils/render-program-card";

import { Title } from "@vshp/ui-components";

function ProgramsPopular() {
  const dispatch = useDispatch();
  const { programsPopular, programsPopularLoading, programsDegreesAreLoading } = useSelector((state) => state.programs);
  const { t } = useTranslation();
  let locale = i18n.language;

  useEffect(() => {
    dispatch(fetchProgramsPopular(locale));
  }, [dispatch, locale]);

  const randomProgramsPopular = _.sampleSize(programsPopular, 3);

  return (
    <div className="page__block">
      <Title type="h2" data-key="program.popular_programs">
        {t("program.popular_programs")}
      </Title>
      <div className="programs-feed__wrapper">{programsPopularLoading || programsDegreesAreLoading ? <Loading /> : renderProgramCard(randomProgramsPopular)}</div>
    </div>
  );
}

export default ProgramsPopular;
