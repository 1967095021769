import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchFaq } from "../../actions/faq";
import { Loading } from "../utils/loading";
import MainLayout from "../layout";
import FaqItem from "./FaqItem";
import faqBg from "../../images/faq.jpg";
import Page from "../page/Page";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

function Faq() {
  const dispatch = useDispatch();
  let locale = i18n.language;
  const { t } = useTranslation();
  const { faq, faqIsLoading } = useSelector((state) => state.faq);
  let content = null;
  let title = null;

  useEffect(() => {
    dispatch(fetchFaq(locale));
  }, [dispatch, locale]);

  if (faqIsLoading) {
    content = <Loading contentPreloader />;
    title = {
      caption: t("ui.loading"),
    };
    return <MainLayout title={title}>{content}</MainLayout>;
  }

  title = {
    caption: "FAQ",
  };

  return (
    <MainLayout title={title}>
      <Page title={t("home.heading.questions")} titleKey="home.heading.questions" coverImg={faqBg}>
        <div className="faq faq_advanced">
          {faq.map((item, key) => (
            <FaqItem question={item.attributes.title} answer={item.attributes.content} key={key} />
          ))}
        </div>
      </Page>
    </MainLayout>
  );
}

export default Faq;
