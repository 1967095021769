import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { formatDate } from "../utils/format-date";
import { languagePathUrl } from "../utils/languagePathUrl";
import cn from "classnames";

function PostCard({ item }) {
  const { currentLanguage } = useSelector((state) => state.global);
  const currentItem = item.attributes;

  const { t } = useTranslation();

  const postItemClass = cn("post-item__image-link", {
    "post-item__image-link-favorite": currentItem.favorite,
  });

  return (
    <div className="posts-feed__item post-item">
      <Link to={`${languagePathUrl(currentLanguage)}/blog/${item.type}/${currentItem.slug}`} className={postItemClass}>
        <img className="post-item__image" src={currentItem.photo.preview} alt={currentItem.title} />
        <img className="post-item__image post-item__image_mobile" src={currentItem.photo["preview-mobile"]} alt={currentItem.title} />
      </Link>
      <div className="post-item__date">
        <span>{formatDate(currentLanguage, currentItem["published-at"])}</span>
        <div className="post-item__date-separator">|</div>
        <span data-key="posts.posts" data-key-press="posts.press">{item.type === "posts" ? t("posts.posts") : t("posts.press")}</span>
      </div>
      <div className="post-item__title">
        <Link to={`${languagePathUrl(currentLanguage)}/blog/${item.type}/${currentItem.slug}`}>{currentItem.title}</Link>
      </div>
      <div className="post-item__intro">
        <p>{currentItem.excerpt}</p>
      </div>
      <Link to={`${languagePathUrl(currentLanguage)}/blog/${item.type}/${currentItem.slug}`} data-key="ui.see_more" className="post-item__more">
        {t("ui.see_more")} {">"}
      </Link>
    </div>
  );
}

export default PostCard;
