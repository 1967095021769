import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Loading } from "../utils/loading";
import { fetchPostsRecent } from "../../actions/posts";

import { Title, Section } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";
import { languagePathUrl } from "../utils/languagePathUrl";
import i18n from "i18next";
import PostCard from "../posts/PostCard";

function PostsRecent() {
  const dispatch = useDispatch();
  let locale = i18n.language;
  const { currentLanguage } = useSelector((state) => state.global);
  const { postsRecent, postsRecentAreLoading } = useSelector((state) => state.posts);

  useEffect(() => {
    dispatch(fetchPostsRecent(locale));
  }, [dispatch, locale]);

  const { t } = useTranslation();

  return (
    <Section>
      <Title type="h2" data-key="home.heading.news">
        {t("home.heading.news")}
      </Title>

      {postsRecentAreLoading ? (
        <Loading />
      ) : (
        <div className="post__recent-items posts-feed__wrapper">
          {postsRecent.map((post, id) => (
            <PostCard item={post} key={id} />
          ))}
        </div>
      )}

      <div className="text-center">
        <Link className="btn btn_primary btn__more" to={`${languagePathUrl(currentLanguage)}/blog/posts`} data-key="home.all_news">
          {t("home.all_news")}
        </Link>
      </div>
    </Section>
  );
}

export default PostsRecent;
