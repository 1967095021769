import axios from "axios";
import { API_URL } from "../config/config";
import { CHECK_DIPLOMAS, CHECK_DIPLOMAS_SENDING, CHECK_DIPLOMAS_LOADED } from "../constants/diplomas";
import { SOURCE } from "../constants/global";

export function checkDiplomasForm(values, locale) {
  return (dispatch) => {
    dispatch(checkDiplomasIsSending(true));
    dispatch(checkDiplomasLoaded(true));

    const config = { source: SOURCE, locale: locale, ...values };

    axios
      .post(`${API_URL}diplomas`, config)
      .then((response) => {
        dispatch(checkDiplomasSuccess(response.data.data));
        dispatch(checkDiplomasIsSending(false));
        dispatch(checkDiplomasLoaded(true));
      })
      .catch((e) => {
        console.log("error loading check diplomas", e);
        dispatch(checkDiplomasLoaded(false));
      });
  };
}

function checkDiplomasSuccess(data) {
  return {
    type: CHECK_DIPLOMAS,
    data,
  };
}

function checkDiplomasIsSending(bool) {
  return {
    type: CHECK_DIPLOMAS_SENDING,
    bool,
  };
}

function checkDiplomasLoaded(bool) {
  return {
    type: CHECK_DIPLOMAS_LOADED,
    bool,
  };
}
