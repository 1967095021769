import MainLayout from "../layout";
import Page from "../page/Page";
import { useTranslation } from "react-i18next";
import { Section } from "@vshp/ui-components";

function Disclaimer() {
  const { t } = useTranslation();

  let title = {
    caption: t("home.disclaimer_title"),
  };

  return (
    <MainLayout title={title}>
      <Page title={t("home.disclaimer_title")} titleKey="home.disclaimer_title">
        <Section>
          <div className="wysiwyg" data-key="home.disclaimer_text" dangerouslySetInnerHTML={{ __html: t("home.disclaimer_text") }}></div>
        </Section>
      </Page>
    </MainLayout>
  );
}

export default Disclaimer;
