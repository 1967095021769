import { useSelector } from "react-redux";
import { Section } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";
import { MapIcon, PhoneIcon, MailIcon } from "../utils/svg";
import { formatPhone } from "../utils/formatPhone";

function ContactsInfo() {
  const { t } = useTranslation();
  const { settings, settingsAreLoading } = useSelector((state) => state.settings);

  let phoneNumber = settings.contacts_phone;
  let formatPhoneNumber = formatPhone(phoneNumber);

  return (
    <Section className="contacts__block">
      <div className="contacts__item contacts__item_medium">
        <MapIcon />
        <div className="contacts__item-label" data-key="contacts.address">
          {t("contacts.address")}
        </div>
        <div className="contacts__item-value" data-key="contacts.address_value">
          {t("contacts.address_value")}
        </div>
      </div>
      <div className="contacts__item contacts__item_short">
        <PhoneIcon />
        <div className="contacts__item-label" data-key="contacts.phone">
          {t("contacts.phone")}
        </div>
        {!settingsAreLoading && phoneNumber != null && (
          <a href={`tel:${formatPhoneNumber.replace(/[^+\d]+/g, "")}`} className="contacts__item-value">
            {phoneNumber}
          </a>
        )}
      </div>
      <div className="contacts__item contacts__item_short">
        <MailIcon />
        <div className="contacts__item-label" data-key="contacts.email">
          {t("contacts.email")}
        </div>
        <a href={`mailto:${t("contacts.email_value")}`} className="contacts__item-value" data-key="contacts.email_value">
          {t("contacts.email_value")}
        </a>
      </div>
    </Section>
  );
}

export default ContactsInfo;
