import MainLayout from "../layout";
import Page from "../page/Page";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Section } from "@vshp/ui-components";
import { useSelector } from "react-redux";
import { languagePathUrl } from "../utils/languagePathUrl";

function ITFaculty() {
  const { currentLanguage } = useSelector((state) => state.global);

  const { t } = useTranslation();
  let title = {
    caption: t("pages.it_faculty.title"),
  };

  return (
    <MainLayout title={title}>
      <Page title={t("pages.it_faculty.title")} titleKey="pages.it_faculty.title" isWidthSmall={true}>
        <Section data-key="pages.it_faculty.text" dangerouslySetInnerHTML={{ __html: t("pages.it_faculty.text") }}></Section>
        <Section>
          <h3>
            <Link to={`${languagePathUrl(currentLanguage)}/programs/bakalavriat/240`}>Bachelor in Information Systems and Technology</Link>
          </h3>
          <h3>
            <Link to={`${languagePathUrl(currentLanguage)}/programs/bakalavriat/246`}>Bachelor in Web Design</Link>
          </h3>
          <h3>
            <Link to={`${languagePathUrl(currentLanguage)}/programs/bakalavriat/245`}>Bachelor in Internet Marketing</Link>
          </h3>
        </Section>
      </Page>
    </MainLayout>
  );
}

export default ITFaculty;
