import Main from "./components/routing";
import "./App.scss";
import { useTranslation } from "react-i18next";

import SmartBanner from "react-smartbanner";
import "../node_modules/react-smartbanner/dist/main.css";

function App() {
  const { t } = useTranslation();

  return (
    <div className="app">
      <SmartBanner
        title={t("smartbanner.title")}
        author={t("smartbanner.author")}
        button={t("smartbanner.button")}
        store={{
          ios: `${t("smartbanner.price-suffix-apple")}`,
          android: `${t("smartbanner.price-suffix-google")}`,
        }}
        price={{ ios: `${t("smartbanner.price")}`, android: `${t("smartbanner.price")}` }}
      />
      <Main />
    </div>
  );
}

export default App;
