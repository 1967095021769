import { Title } from "@vshp/ui-components";
import Slider from "../utils/Slider";

const PageImages = ({ title, images }) => {
  const settings = {
    arrows: false,
    adaptiveHeight: false,
  };

  return (
    <>
      {title !== null && title.length > 0 && <Title type="h2">{title}</Title>}
      {images.length > 1 ? (
        <>
          <Slider className="slider__dots_blue" sliderSettings={settings}>
            {images.map((item, key) => {
              return (
                <div className="slider__item" key={key}>
                  <figure>
                    <img src={item.image} alt="" />
                    <figcaption>{item.content}</figcaption>
                  </figure>
                </div>
              );
            })}
          </Slider>
        </>
      ) : (
        images.map((item, key) => {
          return (
            <figure key={key}>
              <img src={item.image} alt="" />
              <figcaption>{item.content}</figcaption>
            </figure>
          );
        })
      )}
    </>
  );
};

export default PageImages;
