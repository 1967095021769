import { Title } from "@vshp/ui-components";
import offerBg from "../../images/try.jpg";
import { URL } from "../../config/config";
import { useTranslation } from "react-i18next";
import { isLoggedIn } from "../../actions/global";

function Offer() {
  const { t } = useTranslation();
  const loggedIn = isLoggedIn();

  return (
    <div className="page__block page__block_cover page__block_cover_light text-center">
      <img className="page__block-cover" src={offerBg} alt="" />
      <div className="page__block-body page__block-body_center">
        <Title type="h2" decor={false}>
          <span data-key="offer.heading" dangerouslySetInnerHTML={{ __html: t("offer.heading") }}></span>
        </Title>
        {loggedIn ? (
          <a className="btn btn_primary" href={`${URL}/profile`} target="_blank" rel="noopener noreferrer" data-key="offer.button_start">
            {t("offer.button_start")}
          </a>
        ) : (
          <a className="btn btn_primary" href={`${URL}/register`} target="_blank" rel="noopener noreferrer" data-key="offer.button_start">
            {t("offer.button_start")}
          </a>
        )}
      </div>
    </div>
  );
}

export default Offer;
