import { Title } from "@vshp/ui-components";
import missionBg from "../../images/mission.jpg";
import { useTranslation } from "react-i18next";

function Mission() {
  const { t } = useTranslation();

  return (
    <div className="mission page__block page__block_cover page__block_cover_light text-center">
      <img className="page__block-cover" src={missionBg} alt="" />
      <div className="page__block-body page__block-body_small page__block-body_center">
        <Title type="h2" decor={false} data-key="home.heading.our_mission">
          {t("home.heading.our_mission")}
        </Title>
        <div className="page__block-body-text" data-key="home.our_mission_text">
          <p>{t("home.our_mission_text")}</p>
        </div>
      </div>
    </div>
  );
}

export default Mission;
