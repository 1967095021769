import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import MainLayout from "../layout";
import { Loading } from "../utils/loading";
import { renderProgramCard } from "../utils/render-program-card";
import { Title, Section } from "@vshp/ui-components";
import PageNotFound from "../PageNotFound";
import programsBg from "../../images/programs.jpg";
import ProgramsFilter from "./ProgramsFilter";
import { PROGRAMS_TITLE } from "../../constants/title";
import { useTranslation } from "react-i18next";

function ProgramList() {
  const { t } = useTranslation();
  const { programsDegrees, programsDegreesAreLoading, programs, programsAreLoading } = useSelector((state) => state.programs);
  const { slug } = useParams();
  let degreeId = "";

  if (programsDegrees.length > 0) {
    const currentDegreeType = programsDegrees.filter((typeData) => typeData.attributes.slug === slug);
    currentDegreeType.length > 0 ? (degreeId = currentDegreeType[0].id) : (degreeId = null);
  }

  if (slug && !programsAreLoading && degreeId === null) {
    return <PageNotFound />;
  }

  let title = {
    caption: t(PROGRAMS_TITLE),
  };

  return (
    <MainLayout title={title} global={false}>
      <div className="page__header">
        <img className="page__header-cover" src={programsBg} alt={t(PROGRAMS_TITLE)} />
        <div className="page__header-body page__header-body_center">
          <Title decor={false} className="page__header-title" data-key={PROGRAMS_TITLE}>
            {t(PROGRAMS_TITLE)}
          </Title>
        </div>
      </div>

      <div className="page__blocks">
        <Section>
          <ProgramsFilter slug={slug} degreeId={degreeId} />

          <div className="programs-feed">{programsAreLoading || programsDegreesAreLoading ? <Loading /> : renderProgramCard(programs, slug)}</div>
        </Section>
      </div>
    </MainLayout>
  );
}

export default ProgramList;
