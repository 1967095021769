import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Title } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";
import { fetchReviewsHome } from "../../actions/reviews";
import { Loading } from "../utils/loading";
import reviewsBg from "../../images/reviews.jpg";
import i18n from "i18next";
import Slider from "../utils/Slider";

function Reviews() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let locale = i18n.language;
  const { reviewsHome, reviewsHomeAreLoading } = useSelector((state) => state.reviews);

  useEffect(() => {
    dispatch(fetchReviewsHome(locale));
  }, [dispatch, locale]);

  return (
    <>
      {reviewsHomeAreLoading ? (
        <Loading />
      ) : (
        reviewsHome.length > 0 && (
          <div className="page__block page__block_cover">
            <img className="page__block-cover" src={reviewsBg} alt={t("reviews.title")} />
            <div className="page__block-body page__block-body_center">
              <Title type="h2" decor={false} data-key="reviews.title">
                {t("reviews.title")}
              </Title>

              <div className="slider-description">
                <Slider className="slider-description__inner">
                  {reviewsHome.map((item, key) => (
                    <div className="slider__item" key={key}>
                      <div className="slider-description__item">
                        <div className="slider-description__header">
                          <img className="slider-description__photo" src={item.attributes.photo.preview} alt={item.attributes.name} />
                          <div className="slider-description__name">{item.attributes.name}</div>
                          <div className="slider-description__post">{item.attributes.description}</div>
                        </div>
                        <div className="slider-description__content" dangerouslySetInnerHTML={{ __html: item.attributes.content }}></div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
}

export default Reviews;
