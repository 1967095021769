import MainLayout from "../layout";
import { LinkDecor } from "@vshp/ui-components";
import Page from "../page/Page";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

function GeneralInformation() {
  const { t } = useTranslation();
  let locale = i18n.language;

  let title = {
    caption: t("pages.general_information.title"),
  };

  return (
    <MainLayout title={title}>
      <Page title={t("pages.general_information.title")} titleKey="pages.general_information.title">
        {locale !== "ru" ? (
          <LinkDecor link="/docs/9011/Presentation_ENG_2023.pdf">{t("pages.general_information.presentation_title")}</LinkDecor>
        ) : (
          <LinkDecor link="/docs/9017/SIIL_Main_Presentation_2023_RU.pdf">{t("pages.general_information.presentation_title")}</LinkDecor>
        )}
        <LinkDecor link={locale !== "ru" ? "/docs/901b/General_Regulations_SIIL.pdf" : "/docs/9099/OBSCHIE_POLOZHENIYA_2023.2028.pdf"}>{t("pages.general_information.regulations_title")}</LinkDecor>
      </Page>
    </MainLayout>
  );
}

export default GeneralInformation;
