import { combineReducers } from "redux";

import { globalReducers } from "./global";
import { settingsReducers } from "./settings";
import { programsReducers } from "./programs";
import { webinarsReducers } from "./webinars";
import { usersReducers } from "./users";
import { postsReducers } from "./posts";
import { teachersReducers } from "./teachers";
import { partnersReducers } from "./partners";
import { reviewsReducers } from "./reviews";
import { videosReducers } from "./videos";
import { faqReducers } from "./faq";
import { presskitReducers } from "./presskit";
import { documentsReducers } from "./documents";
import { checkDiplomasReducers } from "./diplomas";
import { alumniNetworkReducers } from "./alumni";
import { facultiesReducers } from "./faculties";
import { contactsReducers } from "./contacts";
import { searchResultReducers } from "./search";

export default () =>
  combineReducers({
    global: globalReducers(),
    settings: settingsReducers(),
    programs: programsReducers(),
    webinars: webinarsReducers(),
    users: usersReducers(),
    posts: postsReducers(),
    teachers: teachersReducers(),
    partners: partnersReducers(),
    reviews: reviewsReducers(),
    videos: videosReducers(),
    faq: faqReducers(),
    presskit: presskitReducers(),
    documents: documentsReducers(),
    diplomas: checkDiplomasReducers(),
    alumni: alumniNetworkReducers(),
    contacts: contactsReducers(),
    faculties: facultiesReducers(),
    search: searchResultReducers(),
  });
