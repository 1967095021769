import { Title } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";

function Career({ salary, vacancies }) {
  const { t } = useTranslation();

  return (
    <div className="page__block">
      <Title type="h2" data-key="program.career">{t("program.career")}</Title>
      <div className="career-stat">
        <div className="career-stat__col">
          <div className="career-stat__num">{vacancies}</div>
          <div className="career-stat__title t-h3" data-key="program.career_vacancies">{t("program.career_vacancies")}</div>
        </div>
        <div className="career-stat__col">
          <div className="career-stat__num">{salary.toLocaleString("ru")}</div>
          <div className="career-stat__title t-h3" data-key="program.career_salary">{t("program.career_salary")}</div>
        </div>
      </div>
    </div>
  );
}

export default Career;
