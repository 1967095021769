import { Title } from "@vshp/ui-components";
import { FeaturesCaseIcon, FeaturesLanguageIcon, FeaturesFlippedIcon, FeaturesOnlineIcon } from "../utils/svg";
import { useTranslation } from "react-i18next";

function Features() {
  const { t } = useTranslation();

  return (
    <div className="main__how page__block how how_text">
      <Title type="h2" data-key="home.heading.features">
        {t("home.heading.features")}
      </Title>
      <div className="how__item">
        <div className="how__item-decor"></div>
        <div className="how__item-icon">
          <FeaturesCaseIcon />
        </div>
        <div className="how__item-title" data-key="home.features.subheading_08">
          {t("home.features.subheading_08")}
        </div>
        <div className="how__item-text" data-key="home.features.text_08">
          {t("home.features.text_08")}
        </div>
      </div>
      <div className="how__item">
        <div className="how__item-decor"></div>
        <div className="how__item-icon">
          <FeaturesLanguageIcon />
        </div>
        <div className="how__item-title" data-key="home.features.subheading_01">
          {t("home.features.subheading_01")}
        </div>
        <div className="how__item-text" data-key="home.features.text_01">
          {t("home.features.text_01")}
        </div>
      </div>
      <div className="how__item">
        <div className="how__item-decor"></div>
        <div className="how__item-icon">
          <FeaturesFlippedIcon />
        </div>
        <div className="how__item-title" data-key="home.features.subheading_02">
          {t("home.features.subheading_02")}
        </div>
        <div className="how__item-text" data-key="home.features.text_02">
          {t("home.features.text_02")}
        </div>
      </div>
      <div className="how__item">
        <div className="how__item-decor"></div>
        <div className="how__item-icon">
          <FeaturesOnlineIcon />
        </div>
        <div className="how__item-title" data-key="home.features.subheading_03">
          {t("home.features.subheading_03")}
        </div>
        <div className="how__item-text" data-key="home.features.text_03">
          {t("home.features.text_03")}
        </div>
      </div>
    </div>
  );
}

export default Features;
