import { Title, LinkDecor } from "@vshp/ui-components";

import { useTranslation } from "react-i18next";

const PageDocuments = ({ files, title }) => {
  const { t } = useTranslation();

  return (
    <>
      <Title type="h2" data-key="plan.materials_title">
        {title ? title : t("plan.materials_title")}
      </Title>
      <div className="wysiwyg__documents">
        {files.map((item, key) => {
          return (
            <LinkDecor className="wysiwyg__documents-item" link={item.material_source_url} key={key}>
              {item.material_source_title}
            </LinkDecor>
          );
        })}
      </div>
    </>
  );
};

export default PageDocuments;
