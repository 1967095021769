import YandexShare from "react-yandex-share";
import { useTranslation } from "react-i18next";

const PostShare = ({ currentLanguage, post, socials }) => {
  const { t } = useTranslation();

  let services = socials.toString();

  return (
    <div className="post__share">
      <div className="post__share-label" data-key="ui.share">{t("ui.share")}</div>
      <div className="post__share-buttons socials socials_share">
        <YandexShare content={{ description: post.attributes.title, image: post.attributes.photo.cover }} theme={{ lang: currentLanguage, services: services }} />
      </div>
    </div>
  );
};

export default PostShare;
