import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { CallIcon } from "../../utils/svg";
import { formatPhone } from "../../utils/formatPhone";

function WidgetBtnConsult() {
  const { userConsult } = useSelector((state) => state.users);
  const { settings, settingsAreLoading } = useSelector((state) => state.settings);

  const isConsultLoaded = userConsult.id;
  const { t } = useTranslation();

  let phoneNumber = settings.contacts_phone;
  let formatPhoneNumber = formatPhone(phoneNumber);

  return (
    <>
      {!isConsultLoaded ? (
        !settingsAreLoading && (
          <a className="btn btn_primary btn_small widget-btn" href={settings.contacts_phone !== null ? `tel:${formatPhoneNumber.replace(/[^+\d]+/g, "")}` : "#"}>
            <CallIcon />
            <div className="widget-btn__hint" data-key="ui.btn.call">{t("ui.btn.call")}</div>
          </a>
        )
      ) : (
        <a className="btn btn_primary btn_small widget-btn" href={userConsult.attributes["phone-href"]}>
          <CallIcon />
          <div className="widget-btn__hint" data-key="ui.btn.call">{t("ui.btn.call")}</div>
        </a>
      )}
    </>
  );
}

export default WidgetBtnConsult;
