import { useState } from "react";
import cn from "classnames";
import { ArrowIcon } from "../utils/svg";

function FaqItem({ question, answer, ...props }) {
  const [stateQuestionToggled, setStateFilterToggled] = useState(false);

  const handleQuestionToggle = () => {
    setStateFilterToggled(!stateQuestionToggled);
  };

  const faqItemClass = cn("faq__item", "faq__item_decored", {
    faq__item_open: stateQuestionToggled,
  });

  return (
    <div className={faqItemClass} {...props}>
      <div className="faq__item-q" onClick={handleQuestionToggle}>
        <div className="faq__item-q-icon">
          <ArrowIcon />
        </div>
        <div className="faq__item-q-text">{question}</div>
      </div>
      <div className="faq__item-a" dangerouslySetInnerHTML={{ __html: answer }}></div>
    </div>
  );
}

export default FaqItem;
