import { Title } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";

function About() {
  const { t } = useTranslation();

  return (
    <div className="page__block text-center wysiwyg">
      <div className="page__block-body page__block-body_center page__block-body_small">
        <Title type="h2" decor={false} data-key="home.heading.who_are_we">
          {t("home.heading.who_are_we")}
        </Title>
        <div className="main__about-title" data-key="home.who_are_we.intro">{t("home.who_are_we.intro")}</div>
        <p data-key="home.who_are_we.text">{t("home.who_are_we.text")}</p>
      </div>
    </div>
  );
}

export default About;
