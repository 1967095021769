import { useEffect, useRef } from "react";
import Prism from "prismjs";

function PrismCode(props) {
  const { code, language } = props;
  const ref = useRef(null);

  const highlight = () => {
    if (ref && ref.current) {
      Prism.highlightElement(ref.current);
    }
  };

  useEffect(() => {
    highlight();
  }, []);

  return (
    <pre className="line-numbers">
      <code ref={ref} className={`language-${language}`}>
        {code.trim()}
      </code>
    </pre>
  );
}

export default PrismCode;
