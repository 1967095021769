import { Title } from "@vshp/ui-components";
import greetingBg from "../../images/diploma.png";
import rector from "../../images/rector.jpg";
import { useTranslation } from "react-i18next";

function Greeting() {
  const { t } = useTranslation();

  return (
    <div className="greeting page__block page__block_cover page__block_cover_white">
      <img className="page__block-cover" src={greetingBg} alt={t("home.greeting.title")} />
      <div className="page__block-body page__block-body_center">
        <Title type="h2" decor={false} className="greeting__title-top" data-key="home.greeting.title">
          {t("home.greeting.title")}
        </Title>

        <div className="greeting__body">
          <div className="greeting__col">
            <img className="greeting__photo" src={rector} alt={t("home.greeting.name")} />
            <div className="greeting__post" data-key="home.greeting.post" dangerouslySetInnerHTML={{ __html: t("home.greeting.post") }}></div>
            <div className="greeting__name" data-key="home.greeting.name">{t("home.greeting.name")}</div>
          </div>
          <div className="greeting__col wysiwyg">
            <Title type="block" decor={false} className="greeting__title" data-key="home.greeting.title">
              {t("home.greeting.title")}
            </Title>
            <div className="greeting__text" data-key="home.greeting.text" dangerouslySetInnerHTML={{ __html: t("home.greeting.text") }}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Greeting;
