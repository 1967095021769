import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spin } from "antd";
import { useParams } from "react-router-dom";
import MainLayout from "../layout";
import { Loading } from "../utils/loading";
import { Title, Button } from "@vshp/ui-components";
import PostCard from "./PostCard";
import postsBg from "../../images/page_hero.jpg";
import { loadingIcon } from "../utils/svg";
import { useTranslation } from "react-i18next";
import { fetchPosts, fetchPostsLoadMore } from "../../actions/posts";
import i18n from "i18next";

function Posts() {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const { t } = useTranslation();
  let locale = i18n.language;
  const { posts, postsAreLoading, postsMoreAreLoading } = useSelector((state) => state.posts);
  // const [stateDropdown, setStateDropdown] = useState(false);

  let content = null;
  let title = null;

  useEffect(() => {
    dispatch(fetchPosts(locale));
  }, [dispatch, locale]);

  if (postsAreLoading) {
    content = <Loading contentPreloader />;
    title = {
      caption: t("ui.loading"),
    };
    return <MainLayout title={title}>{content}</MainLayout>;
  }

  title = {
    caption: t("ui.head.posts"),
  };

  // const handlePostsDropdown = () => {
  //   setStateDropdown(!stateDropdown);
  // };

  // const dropdownClass = cn("dropdown", "posts-feed__dropdown", {
  //   dropdown_open: stateDropdown,
  // });

  let currentPosts = slug ? posts.data.filter((type) => type.type === slug) : posts.data;

  const handleLoadMore = () => {
    dispatch(fetchPostsLoadMore(posts.links.next));
  };

  return (
    <MainLayout title={title}>
      <div className="page__header">
        <img className="page__header-cover" src={postsBg} alt="" />
        <div className="page__header-body page__header-body_center">
          <Title decor={false} className="page__header-title" data-key="posts.posts">
            {t("posts.posts")}
          </Title>
        </div>
      </div>

      <div className="page__blocks">
        <div className="page__block">
          <div className="posts-feed">
            {/* <div className={dropdownClass} onClick={handlePostsDropdown}>
              <div className="dropdown__backdrop"></div>
              <div className="dropdown__value">{slug ? (slug === "posts" ? t("posts.posts") : t("posts.press")) : t("posts.all_publications")}</div>
              <div className="dropdown__button">
                <ArrowIcon />
              </div>
              <div className="dropdown__body">
                <Link to="" className="dropdown__link">
                  {t("posts.all_publications")}
                </Link>
                {currentPosts.some((post) => post.type === "posts") && (
                  <Link to="posts" className="dropdown__link">
                    {t("posts.posts")}
                  </Link>
                )}
                {currentPosts.some((post) => post.type === "press") && (
                  <Link to="press" className="dropdown__link">
                    {t("posts.press")}
                  </Link>
                )}
              </div>
            </div> */}

            <div className="posts-feed__wrapper">
              {currentPosts.map((post, id) => (
                <PostCard item={post} slug={slug} key={id} />
              ))}
            </div>
            {posts.links.next && (
              <div className="text-center">
                <Button maxWidth={true} className="post__more" onClick={handleLoadMore} data-key="ui.load_more">
                  {postsMoreAreLoading ? <Spin indicator={loadingIcon} /> : t("ui.load_more")}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default Posts;
