import { combineReducers } from "redux";
import { POSTS, POSTS_LOAD_MORE, POSTS_LOADING, POSTS_LOAD_MORE_LOADING, POST, POST_LOADING, POST_AVAILABLE_LOC, POSTS_RECENT, POSTS_RECENT_LOADING, POST_LOADED } from "../constants/posts";

export function posts(state = [], action) {
  switch (action.type) {
    case POSTS:
      return action.data;
    case POSTS_LOAD_MORE:
      return {
        data: [...state.data, ...action.data.data],
        links: action.data.links,
      };
    default:
      return state;
  }
}

export function postsAreLoading(state = true, action) {
  switch (action.type) {
    case POSTS_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export function postsMoreAreLoading(state = false, action) {
  switch (action.type) {
    case POSTS_LOAD_MORE_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export function postsRecent(state = [], action) {
  switch (action.type) {
    case POSTS_RECENT:
      return action.data;
    default:
      return state;
  }
}

export function postsRecentAreLoading(state = true, action) {
  switch (action.type) {
    case POSTS_RECENT_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export function post(state = {}, action) {
  switch (action.type) {
    case POST:
      return action.data;
    default:
      return state;
  }
}

export function postIsLoading(state = true, action) {
  switch (action.type) {
    case POST_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export function postIsLoaded(state = true, action) {
  switch (action.type) {
    case POST_LOADED:
      return action.bool;
    default:
      return state;
  }
}

export function postErrorAvailableLocales(state = [], action) {
  switch (action.type) {
    case POST_AVAILABLE_LOC:
      return action.data.available_locales;
    default:
      return state;
  }
}

export const postsReducers = () =>
  combineReducers({
    posts: posts,
    postsAreLoading: postsAreLoading,
    postsMoreAreLoading: postsMoreAreLoading,
    post: post,
    postIsLoading: postIsLoading,
    postIsLoaded: postIsLoaded,
    postsRecent: postsRecent,
    postsRecentAreLoading: postsRecentAreLoading,
    postErrorAvailableLocales: postErrorAvailableLocales,
  });
