import { useEffect } from "react";
import { Title } from "@vshp/ui-components";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import FaqItem from "../faq/FaqItem";
import { useTranslation } from "react-i18next";
import { languagePathUrl } from "../utils/languagePathUrl";
import { fetchFaqHome } from "../../actions/faq";
import { Loading } from "../utils/loading";
import i18n from "i18next";

function Faq() {
  const { currentLanguage } = useSelector((state) => state.global);
  const { faqHome, faqHomeAreLoading } = useSelector((state) => state.faq);
  let locale = i18n.language;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFaqHome(locale));
  }, [dispatch, locale]);

  return (
    <div className="page__block">
      <Title type="h2" data-key="home.heading.questions">{t("home.heading.questions")}</Title>
      <div className="faq faq_advanced">
        {faqHomeAreLoading ? <Loading /> : faqHome.length > 0 && faqHome.map((item, key) => <FaqItem question={item.attributes.title} answer={item.attributes.content} key={key} />)}
        <div className="text-center">
          <Link className="btn btn_primary" to={`${languagePathUrl(currentLanguage)}/faq`} data-key="home.all_questions">
            {t("home.all_questions")}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Faq;
