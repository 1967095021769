import { combineReducers } from "redux";
import { REVIEWS, REVIEWS_LOADING, REVIEWS_HOME, REVIEWS_HOME_LOADING, REVIEWS_LOAD_MORE, REVIEWS_LOAD_MORE_LOADING } from "../constants/reviews";

export function reviews(state = [], action) {
  switch (action.type) {
    case REVIEWS:
      return action.data;
    case REVIEWS_LOAD_MORE:
      return {
        data: [...state.data, ...action.data.data],
        links: action.data.links,
      };
    default:
      return state;
  }
}

export function reviewsAreLoading(state = true, action) {
  switch (action.type) {
    case REVIEWS_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export function reviewsMoreAreLoading(state = false, action) {
  switch (action.type) {
    case REVIEWS_LOAD_MORE_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export function reviewsHome(state = [], action) {
  switch (action.type) {
    case REVIEWS_HOME:
      return action.data;
    default:
      return state;
  }
}

export function reviewsHomeAreLoading(state = true, action) {
  switch (action.type) {
    case REVIEWS_HOME_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export const reviewsReducers = () =>
  combineReducers({
    reviews: reviews,
    reviewsAreLoading: reviewsAreLoading,
    reviewsHome: reviewsHome,
    reviewsHomeAreLoading: reviewsHomeAreLoading,
    reviewsMoreAreLoading: reviewsMoreAreLoading,
  });
