import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useLocation } from "react-router-dom";
import { fetchPost, fetchPostsRecent } from "../../actions/posts";
import { formatDate } from "../utils/format-date";
import { Loading } from "../utils/loading";
import MainLayout from "../layout";
import PageNotFound from "../PageNotFound";
import PostCard from "./PostCard";
import { Title } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";
import { languagePathUrl } from "../utils/languagePathUrl";
import programDisciplinesBg from "../../images/disciplines.jpg";
import i18n from "i18next";

import PostShare from "./PostShare";
import PageText from "../page/PageText";
import PageList from "../page/PageList";
import PageCode from "../page/PageCode";
import PageImages from "../page/PageImages";
import PageVideo from "../page/PageVideo";
import PageDocuments from "../page/PageDocuments";
import PageContentButton from "../page/PageContentButton";

function PostPage() {
  const { t } = useTranslation();
  const { titleSlug } = useParams();
  let location = useLocation();
  let content = null;
  let title = null;
  let locale = i18n.language;
  const dispatch = useDispatch();
  const { post, postIsLoading, postIsLoaded, postsRecent, postsRecentAreLoading, postErrorAvailableLocales } = useSelector((state) => state.posts);
  const { currentLanguage } = useSelector((state) => state.global);
  const { settings, settingsAreLoading } = useSelector((state) => state.settings);

  let path = locale === "en" ? location.pathname : location.pathname.substring(3);

  useEffect(() => {
    dispatch(fetchPost(titleSlug, locale));
  }, [dispatch, titleSlug, locale]);

  useEffect(() => {
    if (post.id) {
      dispatch(fetchPostsRecent(locale, post.id));
    }
  }, [dispatch, post.id, locale]);

  if (postIsLoading && postIsLoaded) {
    content = <Loading contentPreloader />;
    title = {
      caption: t("ui.loading"),
    };
    return <MainLayout title={title}>{content}</MainLayout>;
  }

  const handleChangeLanguage = (lng) => () => {
    i18n.changeLanguage(lng);
  };

  const renderLink = (length, lng, title, id) => (
    <Link to={`${languagePathUrl(lng)}${path.length > 0 ? path : "/"}`} key={id} onClick={handleChangeLanguage(lng)}>
      {title}
      {id < length - 1 ? ", " : ""}
    </Link>
  );

  if (!postIsLoaded) {
    if (postErrorAvailableLocales.length > 0) {
      let availableLocales = [];
      !settingsAreLoading &&
        settings.locales.length > 0 &&
        settings.locales.map(([lng, title]) => postErrorAvailableLocales.map((loc) => lng === loc && (availableLocales = [...availableLocales, [lng, title]])));

      const reason = "page.error_not_exist";
      const details = "page.error_not_exist_details";

      return <PageNotFound reason={reason} details={details} isNoTranslate={true} locales={availableLocales.map(([lng, title], id) => renderLink(availableLocales.length, lng, title, id))} />;
    }

    return <PageNotFound />;
  }

  title = {
    caption: post.attributes.title,
  };

  return (
    <MainLayout title={title}>
      <div className="page__header">
        <img className="page__header-cover" src={programDisciplinesBg} alt={post.attributes.title} />
        <div className="page__header-body page__header-body_center page__header-body_theme">
          <Title decor={false} className="page__header-title">
            {post.attributes.title}
          </Title>
        </div>
      </div>
      <div className="page__blocks">
        <div className="page__block">
          <div className="page__block-body_small">
            <div className="page__block">
              <div className="post__date">
                <span>{formatDate(currentLanguage, post.attributes["published-at"])}</span>
                <div className="post__date-separator">|</div>
                <span data-key="posts.posts" data-key-press="posts.press">{post.type === "posts" ? t("posts.posts") : t("posts.press")}</span>
              </div>

              {!settingsAreLoading && settings.social_share.length > 0 && <PostShare currentLanguage={currentLanguage} post={post} socials={settings.social_share} />}

              <img className="post__image" src={post.attributes.photo.cover} alt={post.attributes.title} />
              <img className="post__image post__image_mobile" src={post.attributes.photo["preview-mobile"]} alt={post.attributes.title} />
            </div>

            {post.attributes.contents.map((item, key) => {
              let content = null;
              switch (item.kind) {
                case "text":
                  content = <PageText text={item.content} title={item.title} />;
                  break;
                case "list":
                  content = <PageList list={item.contents} title={item.title} />;
                  break;
                case "image":
                  content = <PageImages images={item.contents} title={item.title} />;
                  break;
                case "video":
                  content = <PageVideo video={item.content} title={item.title} />;
                  break;
                case "code":
                  content = <PageCode code={item.content} title={item.title} />;
                  break;
                case "button":
                  content = <PageContentButton button={item.data} text={item.content} title={item.title} />;
                  break;
                case "documents":
                  content = <PageDocuments files={item.contents} title={item.title} />;
                  break;
                default:
                  break;
              }
              return (
                <div key={key} className="page__block page__block_border">
                  <div className="wysiwyg">{content}</div>
                </div>
              );
            })}

            <Link to={`${languagePathUrl(currentLanguage)}/blog`} className="btn btn_primary btn_max-width-sm post__back" data-key="blog.to_all_posts">
              {t("blog.to_all_posts")}
            </Link>
          </div>
        </div>

        <div className="page__block post__recent">
          <Title type="h2" decor={false} className="t-h2 post__recent-title" data-key="blog.recent_title">
            {t("blog.recent_title")}
          </Title>
          {postsRecentAreLoading ? (
            <Loading />
          ) : (
            <div className="post__recent-items posts-feed__wrapper">
              {postsRecent.map((post, id) => (
                <PostCard item={post} key={id} />
              ))}
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
}

export default PostPage;
