import { Title } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";

function Steps() {
  const { t } = useTranslation();

  return (
    <div className="page__block page__block_border main__info info info_counter">
      <Title type="h2" data-key="home.heading.steps">
        {t("home.heading.steps")}
      </Title>
      <div className="info__item">
        <div className="info__item-label">
          <div className="info__item-label-title" data-key="home.steps.subheading_01">
            {t("home.steps.subheading_01")}
          </div>
          <div className="info__item-label-text" data-key="home.steps.text_01" dangerouslySetInnerHTML={{ __html: t("home.steps.text_01") }}></div>
        </div>
        <div className="info__item-decor"></div>
      </div>
      <div className="info__item">
        <div className="info__item-label">
          <div className="info__item-label-title" data-key="home.steps.subheading_02">
            {t("home.steps.subheading_02")}
          </div>
          <div className="info__item-label-text" data-key="home.steps.text_02" dangerouslySetInnerHTML={{ __html: t("home.steps.text_02") }}></div>
        </div>
        <div className="info__item-decor"></div>
      </div>
      <div className="info__item">
        <div className="info__item-label">
          <div className="info__item-label-title" data-key="home.steps.subheading_03">
            {t("home.steps.subheading_03")}
          </div>
          <div className="info__item-label-text" data-key="home.steps.text_03" dangerouslySetInnerHTML={{ __html: t("home.steps.text_03") }}></div>
        </div>
        <div className="info__item-decor"></div>
      </div>
      <div className="info__item">
        <div className="info__item-label">
          <div className="info__item-label-title" data-key="home.steps.subheading_04">
            {t("home.steps.subheading_04")}
          </div>
          <div className="info__item-label-text" data-key="home.steps.text_04" dangerouslySetInnerHTML={{ __html: t("home.steps.text_04") }}></div>
        </div>
        <div className="info__item-decor"></div>
      </div>
      <div className="info__item">
        <div className="info__item-label">
          <div className="info__item-label-title" data-key="home.steps.subheading_05">
            {t("home.steps.subheading_05")}
          </div>
          <div className="info__item-label-text" data-key="home.steps.text_05" dangerouslySetInnerHTML={{ __html: t("home.steps.text_05") }}></div>
        </div>
        <div className="info__item-decor"></div>
      </div>
    </div>
  );
}

export default Steps;
