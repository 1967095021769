import { Button } from "@vshp/ui-components";
import { ArrowIcon } from "./svg";

export const SlickButtonNext = ({ currentSlide, slideCount, children, ...props }) => (
  <Button className="slider-arrow slider-prev" size="small" corners="square" icon={true} {...props}>
    <ArrowIcon />
  </Button>
);

export const SlickButtonPrev = ({ currentSlide, slideCount, children, ...props }) => (
  <Button className="slider-arrow slider-next" size="small" corners="square" icon={true} {...props}>
    <ArrowIcon />
  </Button>
);
