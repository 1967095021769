import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import MainLayout from "./layout";
import { Title } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";
import { languagePathUrl } from "./utils/languagePathUrl";

const PageNotFound = (props) => {
  const { t } = useTranslation();
  const { currentLanguage } = useSelector((state) => state.global);

  const errorContent = (
    <div className="not-found">
      <div className="not-found__inner">
        <div className="not-found__error">{props.error}</div>
        <Title decor={false} className="not-found__title" data-key={props.reason}>
          {t(props.reason)}
        </Title>

        <div className="not-found__text" data-key={props.details}>
          {t(props.details)}
          {props.isNoTranslate && " "}
          {props.isNoTranslate && props.locales}
        </div>

        <div className="not-found__btn">
          <Link className="btn btn_outline btn_max-width-sm" to={`${languagePathUrl(currentLanguage)}/`} data-key="ui.back_to_main">
            {t("ui.back_to_main")}
          </Link>
        </div>
      </div>
    </div>
  );

  return props.full ? <MainLayout title={{ caption: t("ui.page_not_found") }}>{errorContent}</MainLayout> : errorContent;
};

PageNotFound.defaultProps = {
  full: true,
  error: 404,
  reason: "error.reason",
  details: "error.details",
  locales: "error.locales",
  isNoTranslate: false,
};

export default PageNotFound;
