import MainLayout from "../layout";
import Page from "../page/Page";
import { LinkDecor } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";

function StrategicPlans() {
  const { t } = useTranslation();
  let title = {
    caption: t("pages.strategic_plans.title"),
  };

  return (
    <MainLayout title={title}>
      <Page title={t("pages.strategic_plans.title")} titleKey="pages.strategic_plans.title" isWidthSmall={true}>
        <LinkDecor link="/docs/9013/STRTEGIC_PLAN_2022-2026.pdf">STRATEGIC PLAN 2022-2026</LinkDecor>
      </Page>
    </MainLayout>
  );
}

export default StrategicPlans;
