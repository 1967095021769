import axios from "axios";
import { TEACHERS, TEACHERS_LOADING } from "../constants/teachers";

import { API_URL } from "../config/config";
import { SOURCE } from "../constants/global";

export function fetchTeachers(locale) {
  return (dispatch) => {
    dispatch(teachersAreLoading(true));

    const config = { params: { source: SOURCE, locale: locale } };

    axios
      .get(`${API_URL}teachers`, config)
      .then((response) => {
        return response;
      })
      .then((response) => {
        dispatch(teachersSuccess(response.data.data));
        dispatch(teachersAreLoading(false));

        return response;
      })
      .catch((e) => console.log("error loading teachers", e));
  };
}

function teachersAreLoading(bool) {
  return {
    type: TEACHERS_LOADING,
    bool,
  };
}

function teachersSuccess(data) {
  return {
    type: TEACHERS,
    data,
  };
}
