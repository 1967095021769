export const POSTS = "POSTS";
export const POSTS_LOAD_MORE = "POSTS_LOAD_MORE";
export const POSTS_LOADING = "POSTS_LOADING";
export const POSTS_LOAD_MORE_LOADING = "POSTS_LOAD_MORE_LOADING";
export const POST = "POST";
export const POST_LOADING = "POST_LOADING";
export const POST_LOADED = "POST_LOADED";
export const POST_AVAILABLE_LOC = "POST_AVAILABLE_LOC";
export const POSTS_RECENT = "POSTS_RECENT";
export const POSTS_RECENT_LOADING = "POSTS_RECENT_LOADING";
