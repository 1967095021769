import MainLayout from "../layout";
import Page from "../page/Page";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Section, Title, Collapse } from "@vshp/ui-components";
import { useSelector } from "react-redux";
import { languagePathUrl } from "../utils/languagePathUrl";

function QualityAssuranceOffice() {
  const { currentLanguage } = useSelector((state) => state.global);
  const { t } = useTranslation();
  let title = {
    caption: t("pages.quality_assurance_office.title"),
  };

  return (
    <MainLayout title={title}>
      <Page title={t("pages.quality_assurance_office.title")} titleKey="pages.quality_assurance_office.title">
        <Section border={true}>
          <Link className="btn btn_primary" to={`${languagePathUrl(currentLanguage)}/strategic-plans`} data-key="pages.quality_assurance_office.strategic.btn">
            {t("pages.quality_assurance_office.strategic.btn")}
          </Link>
          <br />
          <Link className="btn btn_primary space_top" to={`${languagePathUrl(currentLanguage)}/annual-reports`} data-key="pages.quality_assurance_office.annual.btn">
            {t("pages.quality_assurance_office.annual.btn")}
          </Link>
          <br />
          <Link className="btn btn_primary space_top" to={`${languagePathUrl(currentLanguage)}/policies`} data-key="pages.quality_assurance_office.policies.btn">
            {t("pages.quality_assurance_office.policies.btn")}
          </Link>
        </Section>

        <Section>
          <Title type="h2" data-key="pages.quality_assurance_office.management.title">
            {t("pages.quality_assurance_office.management.title")}
          </Title>

          <Collapse>
            <Collapse.Item
              header={t("pages.quality_assurance_office.collapse_01.title")}
              content={t("pages.quality_assurance_office.collapse_01.content")}
              data-key-title="pages.quality_assurance_office.collapse_01.title"
              data-key-content="pages.quality_assurance_office.collapse_01.content"
            />
            <Collapse.Item
              header={t("pages.quality_assurance_office.collapse_02.title")}
              content={t("pages.quality_assurance_office.collapse_02.content")}
              data-key-title="pages.quality_assurance_office.collapse_02.title"
              data-key-content="pages.quality_assurance_office.collapse_02.content"
            />
            <Collapse.Item
              header={t("pages.quality_assurance_office.collapse_03.title")}
              content={t("pages.quality_assurance_office.collapse_03.content")}
              data-key-title="pages.quality_assurance_office.collapse_03.title"
              data-key-content="pages.quality_assurance_office.collapse_03.content"
            />
            <Collapse.Item
              header={t("pages.quality_assurance_office.collapse_04.title")}
              content={t("pages.quality_assurance_office.collapse_04.content")}
              data-key-title="pages.quality_assurance_office.collapse_04.title"
              data-key-content="pages.quality_assurance_office.collapse_04.content"
            />
          </Collapse>
        </Section>
        <Section border={true}>
          <Section.BodySmall>
            <div data-key="pages.quality_assurance_office.text" dangerouslySetInnerHTML={{ __html: t("pages.quality_assurance_office.text") }}></div>
          </Section.BodySmall>
        </Section>

        <Section>
          <Title type="h2" data-key="pages.quality_assurance_office.security.title">
            {t("pages.quality_assurance_office.security.title")}
          </Title>
          <Collapse>
            <Collapse.Item
              header={t("pages.quality_assurance_office.security.collapse_01.title")}
              content={t("pages.quality_assurance_office.security.collapse_01.content")}
              data-key-title="pages.quality_assurance_office.security.collapse_01.title"
              data-key-content="pages.quality_assurance_office.security.collapse_01.content"
            />
            <Collapse.Item
              header={t("pages.quality_assurance_office.security.collapse_02.title")}
              content={t("pages.quality_assurance_office.security.collapse_02.content")}
              data-key-title="pages.quality_assurance_office.security.collapse_02.title"
              data-key-content="pages.quality_assurance_office.security.collapse_02.content"
            />
          </Collapse>
        </Section>
        <Section>
          <Section.BodySmall>
            <div data-key="pages.quality_assurance_office.security.text" dangerouslySetInnerHTML={{ __html: t("pages.quality_assurance_office.security.text") }}></div>
          </Section.BodySmall>
        </Section>
      </Page>
    </MainLayout>
  );
}

export default QualityAssuranceOffice;
