import { combineReducers } from "redux";
import { CONTACTS, CONTACTS_LOADING, FEEDBACK_IS_SENDING, FEEDBACK_SUCCESS } from "../constants/contacts";

export function contacts(state = [], action) {
  switch (action.type) {
    case CONTACTS:
      return action.data;
    default:
      return state;
  }
}

export function contactsAreLoading(state = true, action) {
  switch (action.type) {
    case CONTACTS_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export function feedbackSuccess(state = false, action) {
  switch (action.type) {
    case FEEDBACK_SUCCESS:
      return action.bool;
    default:
      return state;
  }
}

export function feedbackAreLoading(state = false, action) {
  switch (action.type) {
    case FEEDBACK_IS_SENDING:
      return action.bool;
    default:
      return state;
  }
}

export const contactsReducers = () =>
  combineReducers({
    contacts: contacts,
    contactsAreLoading: contactsAreLoading,
    feedbackSuccess: feedbackSuccess,
    feedbackAreLoading: feedbackAreLoading,
  });
