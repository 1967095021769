import { combineReducers } from "redux";
import {
  WEBINARS_DATA,
  WEBINARS_DATA_LOADING,
  WEBINAR_IS_LOADING,
  WEBINAR_LOADED,
  WEBINAR,
} from "../constants/webinars";

export function webinarsData(state = [], action) {
  switch (action.type) {
    case WEBINARS_DATA:
      return action.data;
    default:
      return state;
  }
}

export function webinarsDataAreLoading(state = true, action) {
  switch (action.type) {
    case WEBINARS_DATA_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export function webinarIsLoading(state = true, action) {
  switch (action.type) {
    case WEBINAR_IS_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export function webinarLoaded(state = false, action) {
  switch (action.type) {
    case WEBINAR_LOADED:
      return action.bool;
    default:
      return state;
  }
}

export function webinar(state = {}, action) {
  switch (action.type) {
    case WEBINAR:
      return action.data;
    default:
      return state;
  }
}

export const webinarsReducers = () =>
  combineReducers({
    webinarsData: webinarsData,
    webinarsDataAreLoading: webinarsDataAreLoading,
    webinarIsLoading: webinarIsLoading,
    webinarLoaded: webinarLoaded,
    webinar: webinar,
  });
