import axios from "axios";
import { PARTNERS, PARTNERS_LOADING } from "../constants/partners";

import { API_URL } from "../config/config";
import { SOURCE } from "../constants/global";

export function fetchPartners(locale) {
  return (dispatch) => {
    dispatch(partnersAreLoading(true));

    const config = { params: { source: SOURCE, locale: locale } };

    axios
      .get(`${API_URL}partners`, config)
      .then((response) => {
        return response;
      })
      .then((response) => {
        dispatch(partnersSuccess(response.data.data));
        dispatch(partnersAreLoading(false));

        return response;
      })
      .catch((e) => console.log("error loading partners", e));
  };
}

function partnersAreLoading(bool) {
  return {
    type: PARTNERS_LOADING,
    bool,
  };
}

function partnersSuccess(data) {
  return {
    type: PARTNERS,
    data,
  };
}
