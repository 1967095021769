import { useState } from "react";
import { CookieIcon } from "../utils/svg";
import { useTranslation } from "react-i18next";
import { Button } from "@vshp/ui-components";
import { setNoticeCookies } from "../../actions/global";

function CookiesNotice() {
  const [isOpen, setOpen] = useState(true);

  const { t } = useTranslation();

  const handleBtnAccept = () => {
    setNoticeCookies();
    setOpen(false);
  };

  return (
    <>
      {isOpen && (
        <div className="notice notice_white notice_fixed notice_bottom">
          <div className="notice__inner">
            <div className="notice__info-icon">
              <CookieIcon />
            </div>
            <div className="notice__content">
              <div className="notice__title wysiwyg" data-key="ui.cookies_accept_title" dangerouslySetInnerHTML={{ __html: t("ui.cookies_accept_title") }}></div>
              <div className="notice__text wysiwyg" data-key="ui.cookies_accept" dangerouslySetInnerHTML={{ __html: t("ui.cookies_accept") }}></div>
            </div>
            <Button className="notice__action" onClick={handleBtnAccept} data-key="ui.cookies_accept_button">
              {t("ui.cookies_accept_button")}
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default CookiesNotice;
