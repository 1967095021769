import { Section, Title } from "@vshp/ui-components";
import MainLayout from "../layout";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import pageHero from "../../images/page_hero.jpg";
import { useSelector } from "react-redux";
import { languagePathUrl } from "../utils/languagePathUrl";

const StudentServices = () => {
  const { currentLanguage } = useSelector((state) => state.global);
  const { t } = useTranslation();

  let title = {
    caption: t("pages.student_services.title"),
  };

  return (
    <MainLayout title={title}>
      <div className="page__header">
        <img className="page__header-cover" src={pageHero} alt={t("pages.student_services.title")} />
        <div className="page__header-body page__header-body_center">
          <Title decor={false} className="page__header-title" data-key="pages.student_services.title">
            {t("pages.student_services.title")}
          </Title>
        </div>
      </div>
      <div className="page__blocks wysiwyg">
        <Section textSizeLg={true} data-key="pages.student_services.subtitle" dangerouslySetInnerHTML={{ __html: t("pages.student_services.subtitle") }}></Section>
        <Section border={true}>
          <Title type="h2" data-key="pages.student_services.support.title">
            {t("pages.student_services.support.title")}
          </Title>
          <div data-key="pages.student_services.support.text" dangerouslySetInnerHTML={{ __html: t("pages.student_services.support.text") }}></div>
          <Link className="btn btn_primary space_top" to={`${languagePathUrl(currentLanguage)}/student-services/academic-assistance-team`} data-key="pages.student_services.support.btn">
            {t("pages.student_services.support.btn")}
          </Link>
        </Section>
        <Section border={true}>
          <Title type="h2" data-key="pages.student_services.advice.title">
            {t("pages.student_services.advice.title")}
          </Title>
          <div data-key="pages.student_services.advice.text" dangerouslySetInnerHTML={{ __html: t("pages.student_services.advice.text") }}></div>
          <Link className="btn btn_primary space_top" to={`${languagePathUrl(currentLanguage)}/student-services/career-advice-team`} data-key="pages.student_services.advice.btn">
            {t("pages.student_services.advice.btn")}
          </Link>
        </Section>
        <Section border={true}>
          <Title type="h2" data-key="pages.student_services.difficulties.title">
            {t("pages.student_services.difficulties.title")}
          </Title>
          <div data-key="pages.student_services.difficulties.text" dangerouslySetInnerHTML={{ __html: t("pages.student_services.difficulties.text") }}></div>
          <Link className="btn btn_primary space_top" to={`${languagePathUrl(currentLanguage)}/student-services/learning-support-team`} data-key="pages.student_services.difficulties.btn">
            {t("pages.student_services.difficulties.btn")}
          </Link>
        </Section>
        <Section border={true}>
          <Title type="h2" data-key="pages.student_services.network.title">
            {t("pages.student_services.network.title")}
          </Title>
          <div data-key="pages.student_services.network.text" dangerouslySetInnerHTML={{ __html: t("pages.student_services.network.text") }}></div>
          <Link className="btn btn_primary space_top" to={`${languagePathUrl(currentLanguage)}/alumni`} data-key="pages.student_services.network.btn">
            {t("pages.student_services.network.btn")}
          </Link>
        </Section>
        <Section>
          <Title type="h2" data-key="pages.student_services.materials.title">
            {t("pages.student_services.materials.title")}
          </Title>
          <div data-key="pages.student_services.materials.text" dangerouslySetInnerHTML={{ __html: t("pages.student_services.materials.text") }}></div>
        </Section>
      </div>
    </MainLayout>
  );
};

export default StudentServices;
