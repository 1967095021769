import MainLayout from "../layout";
import Page from "../page/Page";
import { useTranslation } from "react-i18next";

function Agreement() {
  const { t } = useTranslation();
  let title = {
    caption: t("pages.agreement.title"),
  };

  return (
    <MainLayout title={title}>
      <Page title={t("pages.agreement.title")} titleKey="pages.agreement.title" intro={t("pages.agreement.intro")} introKey="pages.agreement.intro" isWidthSmall={true}>
        <div data-key="pages.agreement.text" dangerouslySetInnerHTML={{ __html: t("pages.agreement.text") }}></div>
      </Page>
    </MainLayout>
  );
}

export default Agreement;
