import { Title } from "@vshp/ui-components";
import loginBg from "../../images/registration_hero.jpg";
import { useTranslation } from "react-i18next";
import { URL } from "../../config/config";

function Login() {
  const { t } = useTranslation();

  return (
    <div className="page__block page__block_cover page__block_cover_light text-center">
      <img className="page__block-cover" src={loginBg} alt={t("home.login.title")} />
      <div className="page__block-body_small page__block-body_center">
        <Title type="h2" decor={false} data-key="home.login.title">
          {t("home.login.title")}
        </Title>
        <div className="page__block-subtitle" data-key="home.login.subtitle">{t("home.login.subtitle")}</div>
        <a className="btn btn_primary" href={`${URL}/sign_in`} target="_blank" rel="noopener noreferrer" data-key="ui.account">
          {t("ui.account")}
        </a>
      </div>
    </div>
  );
}

export default Login;
