import { combineReducers } from "redux";
import { CHECK_DIPLOMAS, CHECK_DIPLOMAS_SENDING, CHECK_DIPLOMAS_LOADED } from "../constants/diplomas";

export function checkDiplomas(state = {}, action) {
  switch (action.type) {
    case CHECK_DIPLOMAS:
      return action.data;
    default:
      return state;
  }
}

export function checkDiplomasSending(state = true, action) {
  switch (action.type) {
    case CHECK_DIPLOMAS_SENDING:
      return action.bool;
    default:
      return state;
  }
}

export function checkDiplomasLoaded(state = true, action) {
  switch (action.type) {
    case CHECK_DIPLOMAS_LOADED:
      return action.bool;
    default:
      return state;
  }
}

export const checkDiplomasReducers = () =>
  combineReducers({
    checkDiplomas: checkDiplomas,
    checkDiplomasSending: checkDiplomasSending,
    checkDiplomasLoaded: checkDiplomasLoaded,
  });
