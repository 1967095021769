import MainLayout from "../layout";
import Page from "../page/Page";
import { useTranslation } from "react-i18next";
import ModuleLeads from "../shared/ModuleLeads";

function TeachingStaff() {
  const { t } = useTranslation();
  let title = {
    caption: t("pages.teaching_staff.title"),
  };

  return (
    <MainLayout title={title}>
      <Page title={t("pages.teaching_staff.title")} titleKey="pages.teaching_staff.title" isWidthSmall={true}>
        {/* <blockquote className="blockquote_right" data-key="pages.teaching_staff.quote" dangerouslySetInnerHTML={{ __html: t("pages.teaching_staff.quote") }}></blockquote> */}
        <div data-key="pages.teaching_staff.text" dangerouslySetInnerHTML={{ __html: t("pages.teaching_staff.text") }}></div>
        <div className="space_top">
          <ModuleLeads />
        </div>
      </Page>
    </MainLayout>
  );
}

export default TeachingStaff;
