import MainLayout from "../layout";
import Page from "../page/Page";
import { useTranslation } from "react-i18next";
import { Section } from "@vshp/ui-components";
import ContactsInfo from "../shared/ContactsInfo";

function Impressum() {
  const { t } = useTranslation();

  let title = {
    caption: t("pages.impressum.title"),
  };

  return (
    <MainLayout title={title}>
      <Page title={t("pages.impressum.title")} titleKey="pages.impressum.title" isWidthSmall={true} isWysiwyg={false}>
        <Section>
          <div className="wysiwyg" data-key="pages.impressum.text" dangerouslySetInnerHTML={{ __html: t("pages.impressum.text") }}></div>
        </Section>
        <ContactsInfo />
      </Page>
    </MainLayout>
  );
}

export default Impressum;
