import { combineReducers } from "redux";
import { PARTNERS, PARTNERS_LOADING } from "../constants/partners";

export function partners(state = [], action) {
  switch (action.type) {
    case PARTNERS:
      return action.data;
    default:
      return state;
  }
}

export function partnersAreLoading(state = true, action) {
  switch (action.type) {
    case PARTNERS_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export const partnersReducers = () =>
  combineReducers({
    partners: partners,
    partnersAreLoading: partnersAreLoading,
  });
