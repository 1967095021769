import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPartners } from "../../actions/partners";
import { Loading } from "../utils/loading";
import MainLayout from "../layout";
import PartnerItem from "./PartnerItem";
import { Title } from "@vshp/ui-components";
import partnersListBg from "../../images/partners.jpg";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

function PartnersList() {
  const dispatch = useDispatch();
  let locale = i18n.language;
  const { t } = useTranslation();

  const { partners, partnersAreLoading } = useSelector((state) => state.partners);
  let content = null;
  let title = null;

  useEffect(() => {
    if (partners.length < 1) {
      dispatch(fetchPartners(locale));
    }
  }, [dispatch, locale]);

  if (partnersAreLoading) {
    content = <Loading contentPreloader />;
    title = {
      caption: t("ui.loading"),
    };
    return <MainLayout title={title}>{content}</MainLayout>;
  }

  title = {
    caption: t("ui.menu_item_partners"),
  };

  return (
    <MainLayout title={title}>
      <div className="page__header">
        <img className="page__header-cover" src={partnersListBg} alt="" />
        <div className="page__header-body page__header-body_center">
          <Title decor={false} className="page__header-title" data-key="ui.menu_item_partners">
            {t("ui.menu_item_partners")}
          </Title>
          <div className="page__header-intro" data-key="pages.partners.intro" dangerouslySetInnerHTML={{ __html: t("pages.partners.intro") }}></div>
        </div>
      </div>
      <div className="page__blocks">
        <div className="page__block page__block_border">
          <div className="page__block-body_small wysiwyg" data-key="pages.partners.text" dangerouslySetInnerHTML={{ __html: t("pages.partners.text") }}></div>
        </div>
        <div className="page__block">
          <div className="page__block-body">
            <Title type="h2" decor={false} className="t-h3" data-key="partners.title">
              {t("partners.title")}
            </Title>
            <div className="page__block-body_small wysiwyg">
              <p data-key="partners.description">{t("partners.description")}</p>
            </div>
            <PartnerItem partners={partners} />
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default PartnersList;
