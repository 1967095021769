import MainLayout from "../layout";
import Page from "../page/Page";
import { useTranslation } from "react-i18next";
import { Collapse } from "@vshp/ui-components";

function ELearningPlatform() {
  const { t } = useTranslation();
  let title = {
    caption: t("pages.e_learning_platform.title"),
  };

  return (
    <MainLayout title={title}>
      <Page title={t("pages.e_learning_platform.title")} titleKey="pages.e_learning_platform.title" isWidthSmall={true}>
        <Collapse>
          <Collapse.Item
            header={t("pages.e_learning_platform.collapse_01.title")}
            content={t("pages.e_learning_platform.collapse_01.content")}
            data-key-title="pages.e_learning_platform.collapse_01.title"
            data-key-content="pages.e_learning_platform.collapse_01.content"
          />
          <Collapse.Item
            header={t("pages.e_learning_platform.collapse_02.title")}
            content={t("pages.e_learning_platform.collapse_02.content")}
            data-key-title="pages.e_learning_platform.collapse_02.title"
            data-key-content="pages.e_learning_platform.collapse_02.content"
          />
          <Collapse.Item
            header={t("pages.e_learning_platform.collapse_03.title")}
            content={t("pages.e_learning_platform.collapse_03.content")}
            data-key-title="pages.e_learning_platform.collapse_03.title"
            data-key-content="pages.e_learning_platform.collapse_03.content"
          />
        </Collapse>
      </Page>
    </MainLayout>
  );
}

export default ELearningPlatform;
