import { Helmet } from "react-helmet";
import { TITLE } from "../../constants/title";
import { useTranslation } from "react-i18next";

function DocumentTitle(props) {
  const { item, global = true } = props;
  const { t } = useTranslation();

  return (
    <Helmet>
      <title>{`${global ? `${t(TITLE)}` : ""} ${item.caption ? item.caption : ""}`.trim()}</title>
      <meta name="description" content={item.intro ? item.intro : ""} />
      <meta name="og:title" content={`${global ? `${t(TITLE)}` : ""} ${item.caption ? item.caption : ""}`.trim()} />
    </Helmet>
  );
}

export default DocumentTitle;
