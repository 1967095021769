import axios from "axios";
import { POSTS, POSTS_LOADING, POSTS_LOAD_MORE, POSTS_LOAD_MORE_LOADING, POST, POST_LOADING, POST_AVAILABLE_LOC, POSTS_RECENT, POSTS_RECENT_LOADING, POST_LOADED } from "../constants/posts";

import { API_URL } from "../config/config";
import { SOURCE } from "../constants/global";

export function fetchPosts(locale) {
  return (dispatch) => {
    dispatch(postsAreLoading(true));

    const config = { params: { source: SOURCE, locale: locale } };

    axios
      .get(`${API_URL}posts`, config)
      .then((response) => {
        return response;
      })
      .then((response) => {
        dispatch(postsSuccess(response.data));
        dispatch(postsAreLoading(false));

        return response;
      })
      .catch((e) => console.log("error loading posts", e));
  };
}

function postsAreLoading(bool) {
  return {
    type: POSTS_LOADING,
    bool,
  };
}

function postsSuccess(data) {
  return {
    type: POSTS,
    data,
  };
}

export function fetchPostsLoadMore(url) {
  return (dispatch) => {
    dispatch(postsLoadMoreAreLoading(true));

    axios
      .get(`${url}`)
      .then((response) => {
        return response;
      })
      .then((response) => {
        dispatch(postsLoadMoreSuccess(response.data));
        dispatch(postsLoadMoreAreLoading(false));

        return response;
      })
      .catch((e) => console.log("error loading more posts", e));
  };
}

function postsLoadMoreAreLoading(bool) {
  return {
    type: POSTS_LOAD_MORE_LOADING,
    bool,
  };
}

function postsLoadMoreSuccess(data) {
  return {
    type: POSTS_LOAD_MORE,
    data,
  };
}

export function fetchPostsRecent(locale, id) {
  return (dispatch) => {
    dispatch(postsRecentAreLoading(true));

    const config = { params: { source: SOURCE, locale: locale } };
    id && (config.params.except = id);

    axios
      .get(`${API_URL}posts/recent`, config)
      .then((response) => {
        return response;
      })
      .then((response) => {
        dispatch(postsRecentSuccess(response.data.data));
        dispatch(postsRecentAreLoading(false));

        return response;
      })
      .catch((e) => console.log("error loading posts recent", e));
  };
}

function postsRecentAreLoading(bool) {
  return {
    type: POSTS_RECENT_LOADING,
    bool,
  };
}

function postsRecentSuccess(data) {
  return {
    type: POSTS_RECENT,
    data,
  };
}

export function fetchPost(slug, locale) {
  return (dispatch) => {
    dispatch(postIsLoading(true));
    dispatch(postIsLoaded(true));

    const config = { params: { source: SOURCE, locale: locale } };

    axios
      .get(`${API_URL}posts/${slug}`, config)
      .then((response) => {
        return response;
      })
      .then((response) => {
        dispatch(postSuccess(response.data.data));
        dispatch(postIsLoading(false));
        dispatch(postIsLoaded(true));

        return response;
      })
      .catch((e) => {
        console.log("error loading post", e);
        dispatch(postIsLoaded(false));
        dispatch(postErrorAvailableLocales(e.response.data));
      });
  };
}

function postIsLoading(bool) {
  return {
    type: POST_LOADING,
    bool,
  };
}

function postSuccess(data) {
  return {
    type: POST,
    data,
  };
}

function postIsLoaded(bool) {
  return {
    type: POST_LOADED,
    bool,
  };
}

function postErrorAvailableLocales(data) {
  return {
    type: POST_AVAILABLE_LOC,
    data,
  };
}
