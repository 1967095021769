import { Title } from "@vshp/ui-components";

const PageList = ({ title, list }) => {
  return (
    <div className="info info_counter">
      {title !== null && title.length > 0 && <Title type="h2">{title}</Title>}
      {list.map((item, key) => {
        return (
          <div className="info__item" key={key}>
            <div className="info__item-label-text">{item.content}</div>
            <div className="info__item-decor"></div>
          </div>
        );
      })}
    </div>
  );
};

export default PageList;
