import axios from "axios";

import { getRefId, setGetParam, isLoggedIn } from "./global";
import { API_URL } from "../config/config";

import { USER_SESSION, USER_SESSION_IS_LOADING, USER_SESSION_LOADED, USER_CONSULT_INFO, REFERRALS, REFERRALS_ARE_LOADING } from "../constants/users";
import { SOURCE } from "../constants/global";

export function fetchUserInfo() {
  return (dispatch, getState) => {
    const sessionLoaded = getState().users.sessionLoaded;

    if (!isLoggedIn()) {
      dispatch(userSessionIsLoading(false));
      return;
    }

    if (!sessionLoaded) {
      dispatch(userSessionIsLoading(true));

      axios
        .get(`${API_URL}user/session`)
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          dispatch(fetchUserSessionSuccess(response.data));
          dispatch(userSessionIsLoading(false));
          dispatch(userSessionLoaded(true));
        })
        .catch((error) => {
          console.log("error loading user info", error);
          dispatch(userSessionIsLoading(false));
          dispatch(userSessionLoaded(false));
        });
    } else {
      dispatch(userSessionIsLoading(false));
    }
  };
}

export function fetchUserSessionSuccess(user) {
  return {
    type: USER_SESSION,
    user,
  };
}

export function userSessionIsLoading(bool) {
  return {
    type: USER_SESSION_IS_LOADING,
    bool,
  };
}

export function userSessionLoaded(bool) {
  return {
    type: USER_SESSION_LOADED,
    bool,
  };
}

export function fetchConsultInfo(refId, locale) {
  return (dispatch, getState) => {
    let config = {};
    const storedRefId = getRefId();

    if (refId || storedRefId) {
      config = { params: { source: SOURCE, locale: locale, refid: refId ? refId : storedRefId } };
    }

    axios
      .get(`${API_URL}user/consultant`, config)
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        const data = response.data;
        if (data.id) {
          setGetParam("refid", data.id);
        }
        dispatch(fetchUserConsultSuccess(data));
      })
      .catch((error) => console.log("error loading user consult", error));
  };
}

export function fetchUserConsultSuccess(data) {
  return {
    type: USER_CONSULT_INFO,
    data,
  };
}

export function fetchReferrals() {
  return (dispatch) => {
    dispatch(referralsAreLoading(true));

    axios
      .get(`${API_URL}user/referrals`)
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch(referralsSuccess(response.data));
        dispatch(referralsAreLoading(false));
      })
      .catch((error) => console.log("error loading user referrals", error));
  };
}

export function referralsAreLoading(bool) {
  return {
    type: REFERRALS_ARE_LOADING,
    bool,
  };
}

export function referralsSuccess(data) {
  return {
    type: REFERRALS,
    data,
  };
}
