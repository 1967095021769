import MainLayout from "../layout";
import { Collapse } from "@vshp/ui-components";
import Page from "../page/Page";
import { useTranslation } from "react-i18next";

function StudySIIL() {
  const { t } = useTranslation();

  let title = {
    caption: t("pages.study_siil.title"),
  };

  return (
    <MainLayout title={title}>
      <Page title={t("pages.study_siil.title")} titleKey="pages.study_siil.title">
        <Collapse>
          <Collapse.Item
            header={t("pages.study_siil.collapse_01.title")}
            content={t("pages.study_siil.collapse_01.content")}
            data-key-title="pages.study_siil.collapse_01.title"
            data-key-content="pages.study_siil.collapse_01.content"
          />
          <Collapse.Item
            header={t("pages.study_siil.collapse_02.title")}
            content={t("pages.study_siil.collapse_02.content")}
            data-key-title="pages.study_siil.collapse_02.title"
            data-key-content="pages.study_siil.collapse_02.content"
          />
          <Collapse.Item
            header={t("pages.study_siil.collapse_03.title")}
            content={t("pages.study_siil.collapse_03.content")}
            data-key-title="pages.study_siil.collapse_03.title"
            data-key-content="pages.study_siil.collapse_03.content"
          />
          <Collapse.Item
            header={t("pages.study_siil.collapse_04.title")}
            content={t("pages.study_siil.collapse_04.content")}
            data-key-title="pages.study_siil.collapse_04.title"
            data-key-content="pages.study_siil.collapse_04.content"
          />
          <Collapse.Item
            header={t("pages.study_siil.collapse_05.title")}
            content={t("pages.study_siil.collapse_05.content")}
            data-key-title="pages.study_siil.collapse_05.title"
            data-key-content="pages.study_siil.collapse_05.content"
          />
          <Collapse.Item
            header={t("pages.study_siil.collapse_06.title")}
            content={t("pages.study_siil.collapse_06.content")}
            data-key-title="pages.study_siil.collapse_06.title"
            data-key-content="pages.study_siil.collapse_06.content"
          />
        </Collapse>
      </Page>
    </MainLayout>
  );
}

export default StudySIIL;
