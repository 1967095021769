import { Carousel } from "antd";
import { useRef } from "react";
import { SlickButtonNext, SlickButtonPrev } from "./slick-arrow-btns";
import { MAX_INDICATORS_STD, MAX_INDICATORS_COUNT } from "../../constants/global";
import cn from "classnames";

const Slider = ({ sliderSettings, children, ...props }) => {
  const sliderWrapRef = useRef();

  let minDotsCount = 0;
  let maxDotsCount = MAX_INDICATORS_STD;
  let transformCount = 0;

  const getIndicatorType = (number) =>
    cn("", {
      std: number >= minDotsCount && number <= maxDotsCount,
      small: number === minDotsCount - 1 || number === maxDotsCount + 1,
    });

  const handleInit = () => {
    const dotsWrap = sliderWrapRef.current.querySelector(".slick-dots ul");

    if (sliderWrapRef.current.querySelectorAll("li").length > MAX_INDICATORS_COUNT) {
      dotsWrap.parentElement.classList.add("slider__dots_many");
      [...dotsWrap.querySelectorAll("li")].map((item, id) => getIndicatorType(id).length !== 0 && (item.dataset.type = getIndicatorType(id)));
    }
  };

  const handleChange = (oldIndex, newIndex) => {
    const dotsWrap = sliderWrapRef.current.querySelector(".slick-dots ul");
    const dots = dotsWrap.querySelectorAll("li");

    if (dots.length > MAX_INDICATORS_COUNT) {
      const totalCount = dots.length - 1;
      let center = dotsWrap.offsetWidth / 2 - sliderWrapRef.current.querySelector(".slick-dots .slick-active").offsetWidth / 2;
      transformCount = center - dots[newIndex].offsetLeft;

      if (newIndex < minDotsCount || newIndex > maxDotsCount) {
        minDotsCount = newIndex;
        maxDotsCount = newIndex;

        if (newIndex > totalCount - MAX_INDICATORS_STD) {
          maxDotsCount = totalCount;

          if (oldIndex === 0) {
            minDotsCount = maxDotsCount - MAX_INDICATORS_STD;
            transformCount = dotsWrap.offsetWidth - dots[newIndex].offsetLeft - dots[newIndex].offsetWidth;
          } else {
            minDotsCount = oldIndex;
            transformCount = dotsWrap.offsetWidth - dots[totalCount].offsetLeft - dots[totalCount].offsetWidth;
          }
        }

        if (newIndex <= MAX_INDICATORS_STD) {
          if (oldIndex === totalCount) {
            minDotsCount = 0;
            maxDotsCount = minDotsCount + MAX_INDICATORS_STD;
            transformCount = 0;
          } else {
            minDotsCount = maxDotsCount - MAX_INDICATORS_STD;
          }
        }

        dotsWrap.style.marginLeft = `${transformCount}px`;
      }

      [...dots].map((item, id) => {
        const currentClass = getIndicatorType(id, newIndex);
        if (item.dataset.type === currentClass || (!item.dataset.type && currentClass.length === 0)) {
          return item;
        }
        return (item.dataset.type = currentClass);
      });
    }
  };

  const settings = {
    appendDots: function (dots) {
      return (
        <div>
          <ul>{dots}</ul>
        </div>
      );
    },
    beforeChange: (oldIndex, newIndex) => {
      handleChange(oldIndex, newIndex);
    },
    onReInit: () => {
      handleInit();
    },
    arrows: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    centerMode: false,
    lazyLoad: "ondemand",
    nextArrow: <SlickButtonNext />,
    prevArrow: <SlickButtonPrev />,
  };

  return (
    <div ref={sliderWrapRef}>
      <Carousel {...settings} {...sliderSettings} {...props}>
        {children}
      </Carousel>
    </div>
  );
};

export default Slider;
