import { Title } from "@vshp/ui-components";
import installmentsBg from "../../images/pay.jpg";
import { LaptopIcon, PresentationIcon, RubleIcon } from "../utils/svg";
import { useTranslation } from "react-i18next";

function Installment() {
  const { t } = useTranslation();

  return (
    <div className="installment page__block page__block_cover page__block_cover_white text-center">
      <img className="page__block-cover" src={installmentsBg} alt="" />
      <div className="page__block-body page__block-body_center">
        <Title type="h2" decor={false} data-key="installment.title">
          {t("installment.title")}
        </Title>

        <div className="installment-info">
          <div className="installment-info__item">
            <div className="installment-info__icon">
              <LaptopIcon />
            </div>
            <div className="installment-info__title t-h3" data-key="installment.info_title_01">{t("installment.info_title_01")}</div>
          </div>
          <div className="installment-info__item">
            <div className="installment-info__icon">
              <PresentationIcon />
            </div>
            <div className="installment-info__title t-h3" data-key="installment.info_title_02">{t("installment.info_title_02")}</div>
          </div>
          <div className="installment-info__item">
            <div className="installment-info__icon">
              <RubleIcon />
            </div>
            <div className="installment-info__title t-h3" data-key="installment.info_title_03">{t("installment.info_title_03")}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Installment;
