import { combineReducers } from "redux";
import { VIDEOS, VIDEOS_LOADING } from "../constants/videos";

export function videos(state = [], action) {
  switch (action.type) {
    case VIDEOS:
      return action.data;
    default:
      return state;
  }
}

export function videosAreLoading(state = true, action) {
  switch (action.type) {
    case VIDEOS_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export const videosReducers = () =>
  combineReducers({
    videos: videos,
    videosAreLoading: videosAreLoading,
  });
