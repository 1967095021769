import MainLayout from "../layout";
import Page from "../page/Page";
import { useTranslation } from "react-i18next";

function LearningSupport() {
  const { t } = useTranslation();
  let title = {
    caption: t("pages.learning_support.title"),
  };

  return (
    <MainLayout title={title}>
      <Page title={t("pages.learning_support.title")} titleKey="pages.learning_support.title" isWidthSmall={true}>
        <div data-key="pages.learning_support.text" dangerouslySetInnerHTML={{ __html: t("pages.learning_support.text") }}></div>
      </Page>
    </MainLayout>
  );
}

export default LearningSupport;
