import { useState } from "react";
import { Form, Input, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { sendFeedback } from "../../actions/contacts";
import { loadingIcon, UserIcon, MailIcon } from "../utils/svg";
import { Title, Button, Section } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function FormContacts() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { TextArea } = Input;

  const { feedbackSuccess, feedbackAreLoading } = useSelector((state) => state.contacts);
  const { t } = useTranslation();
  let locale = i18next.language;

  const onFinish = (values) => {
    let formValues = { ...values, locale: locale };
    dispatch(sendFeedback(formValues));
  };

  return (
    <Section>
      <Title decor={false} data-key="contacts.feedback_title">
        {t("contacts.feedback_title")}
      </Title>
      {feedbackSuccess ? (
        <div className="wysiwyg" data-key="contacts.feedback.feedback_thanks">
          {t("contacts.feedback.feedback_thanks")}
        </div>
      ) : (
        <Form colon={false} onFinish={onFinish} autoComplete="off" className="form feedback__form" form={form}>
          <Form.Item
            name="name"
            className="form__item"
            rules={[
              {
                required: true,
                message: t("contacts.feedback.enter_name"),
              },
            ]}
            validateTrigger="onSubmit"
            data-key="contacts.feedback.enter_name"
          >
            <Input placeholder={t("contacts.feedback.placeholder_name")} prefix={<UserIcon />} data-key="contacts.feedback.placeholder_name" />
          </Form.Item>
          <Form.Item
            name="email"
            className="form__item"
            rules={[
              {
                required: true,
                message: t("contacts.feedback.enter_email"),
              },
              {
                type: "email",
                message: t("contacts.feedback.enter_correct_email"),
              },
            ]}
            validateTrigger="onSubmit"
            data-key="contacts.feedback.enter_email, contacts.feedback.enter_correct_email"
          >
            <Input placeholder={t("contacts.feedback.placeholder_email")} prefix={<MailIcon />} data-key="contacts.feedback.placeholder_email" />
          </Form.Item>
          <Form.Item
            name="message"
            className="form__item feedback__form-message"
            rules={[
              {
                required: true,
                message: t("contacts.feedback.enter_message"),
                whitespace: true,
              },
            ]}
            validateTrigger="onSubmit"
          >
            <TextArea autoSize={{ minRows: 5 }} placeholder={t("contacts.feedback.enter_message")} data-key="contacts.feedback.enter_message" />
          </Form.Item>

          <Button type="submit" maxWidth={true} data-key="ui.send" className="feedback__form-btn">
            {feedbackAreLoading ? <Spin indicator={loadingIcon} /> : t("ui.send")}
          </Button>
        </Form>
      )}
    </Section>
  );
}

export default FormContacts;
