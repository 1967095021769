import { Title } from "@vshp/ui-components";
import PrismCode from "../utils/prism-code";

const PageCode = ({ title, code }) => {
  return (
    <>
      {title !== null && title.length > 0 && <Title type="h2">{title}</Title>}
      <PrismCode code={code} language="js" />
    </>
  );
};

export default PageCode;
