import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Title } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";
import { languagePathUrl } from "../utils/languagePathUrl";

function Levels() {
  const { programsDegrees, programsDegreesAreLoading } = useSelector((state) => state.programs);
  const { currentLanguage } = useSelector((state) => state.global);
  const { t } = useTranslation();

  return (
    <div className="page__block">
      <Title type="h2" data-key="home.heading.main_levels">{t("home.heading.main_levels")}</Title>
      <div className="levels">
        {!programsDegreesAreLoading && programsDegrees.length > 0
          ? programsDegrees.map((item, ikey) => (
              <Link className="levels__item" to={`${languagePathUrl(currentLanguage)}/programs/${item.attributes.slug}`} key={ikey}>
                <div className="levels__item-icon" dangerouslySetInnerHTML={{ __html: item.attributes.icon }}></div>
                <h4 className="levels__item-title">{item.attributes.title}</h4>
              </Link>
            ))
          : null}
      </div>
    </div>
  );
}

export default Levels;
