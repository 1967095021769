import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Title, Button } from "@vshp/ui-components";
import MainLayout from "../layout";
import { useTranslation } from "react-i18next";
import pageHero from "../../images/page_hero.jpg";
import loginBg from "../../images/registration_hero.jpg";
import { isLoggedIn } from "../../actions/global";
import { fetchUserInfo } from "../../actions/users";
import { joinAlumniNetwork, exitAlumniNetwork } from "../../actions/alumni";
import Alumni from "./Alumni";
import i18n from "i18next";
import _ from "lodash";

const AlumniNetwork = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loggedIn = isLoggedIn();
  const { alumniNetworkData } = useSelector((state) => state.alumni);
  const { session, sessionLoaded } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(fetchUserInfo());
  }, [dispatch]);

  let locale = i18n.language;

  let title = {
    caption: t("pages.alumni_network.title"),
  };

  const handleJoin = () => {
    dispatch(joinAlumniNetwork(locale));
  };

  const handleExit = () => {
    dispatch(exitAlumniNetwork(locale));
  };

  return (
    <MainLayout title={title}>
      <div className="page__header">
        <img className="page__header-cover" src={pageHero} alt={t("pages.alumni_network.title")} />
        <div className="page__header-body page__header-body_center">
          <Title decor={false} className="page__header-title" data-key="pages.alumni_network.title">
            {t("pages.alumni_network.title")}
          </Title>
        </div>
      </div>
      <div className="page__blocks">
        <div className="page__block page__text_big wysiwyg" data-key="pages.alumni_network.subtitle" dangerouslySetInnerHTML={{ __html: t("pages.alumni_network.subtitle") }}></div>
        <div className="page__block wysiwyg">
          <Title type="h2" data-key="pages.alumni_network.useful.title">{t("pages.alumni_network.useful.title")}</Title>
          <div data-key="pages.alumni_network.useful.text" dangerouslySetInnerHTML={{ __html: t("pages.alumni_network.useful.text") }}></div>
        </div>
        <div className="page__block wysiwyg">
          <Title data-key="pages.alumni_network.eligible.title" type="h2">{t("pages.alumni_network.eligible.title")}</Title>
          <div data-key="pages.alumni_network.eligible.text" dangerouslySetInnerHTML={{ __html: t("pages.alumni_network.eligible.text") }}></div>
        </div>

        <div className="page__block page__block_cover page__block_cover_light">
          <img className="page__block-cover" src={loginBg} alt={t("pages.alumni_network.login.title")} />
          <div className="page__block-body_small page__block-body_center text-center">
            <Title type="h2" decor={false} data-key="pages.alumni_network.login.title">
              {t("pages.alumni_network.login.title")}
            </Title>
            <div className="page__block-subtitle" data-key="pages.alumni_network.login.subtitle" dangerouslySetInnerHTML={{ __html: t("pages.alumni_network.login.subtitle") }}></div>
            <Button
              disabled={!loggedIn}
              onClick={
                _.isEmpty(alumniNetworkData)
                  ? sessionLoaded && session.attributes["alumni-network"]
                    ? handleExit
                    : handleJoin
                  : alumniNetworkData.attributes["alumni-network"]
                  ? handleExit
                  : handleJoin
              }

              data-key-logout="pages.alumni_network.logout.btn"
              data-key-login="pages.alumni_network.login.btn"
            >
              {_.isEmpty(alumniNetworkData)
                ? sessionLoaded && session.attributes["alumni-network"]
                  ? t("pages.alumni_network.logout.btn")
                  : t("pages.alumni_network.login.btn")
                : alumniNetworkData.attributes["alumni-network"]
                ? t("pages.alumni_network.logout.btn")
                : t("pages.alumni_network.login.btn")}
            </Button>
          </div>
          {_.isEmpty(alumniNetworkData) ? sessionLoaded && session.attributes["alumni-network"] && <Alumni /> : alumniNetworkData.attributes["alumni-network"] && <Alumni />}
        </div>

        <div className="page__block wysiwyg">
          <Title type="h2" data-key="pages.alumni_network.code.title">{t("pages.alumni_network.code.title")}</Title>

          <h2 data-key="pages.alumni_network.code.general.title">{t("pages.alumni_network.code.general.title")}</h2>
          <div data-key="pages.alumni_network.code.general.text" dangerouslySetInnerHTML={{ __html: t("pages.alumni_network.code.general.text") }}></div>

          <h2 data-key="pages.alumni_network.code.conduct.title">{t("pages.alumni_network.code.conduct.title")}</h2>
          <div data-key="pages.alumni_network.code.conduct.text" dangerouslySetInnerHTML={{ __html: t("pages.alumni_network.code.conduct.text") }}></div>

          <h2 data-key="pages.alumni_network.code.activity.title">{t("pages.alumni_network.code.activity.title")}</h2>
          <div data-key="pages.alumni_network.code.activity.text" dangerouslySetInnerHTML={{ __html: t("pages.alumni_network.code.activity.text") }}></div>

          <h2 data-key="pages.alumni_network.code.communication.title">{t("pages.alumni_network.code.communication.title")}</h2>
          <div data-key="pages.alumni_network.code.communication.text" dangerouslySetInnerHTML={{ __html: t("pages.alumni_network.code.communication.text") }}></div>

          <h2 data-key="pages.alumni_network.code.privacy.title">{t("pages.alumni_network.code.privacy.title")}</h2>
          <div data-key="pages.alumni_network.code.privacy.text" dangerouslySetInnerHTML={{ __html: t("pages.alumni_network.code.privacy.text") }}></div>

          <h2 data-key="pages.alumni_network.code.agreement.title">{t("pages.alumni_network.code.agreement.title")}</h2>
          <div data-key="pages.alumni_network.code.agreement.text" dangerouslySetInnerHTML={{ __html: t("pages.alumni_network.code.agreement.text") }}></div>
        </div>
      </div>
    </MainLayout>
  );
};

export default AlumniNetwork;
