import MainLayout from "../layout";
import Page from "../page/Page";
import { useTranslation } from "react-i18next";

function Academic() {
  const { t } = useTranslation();
  let title = {
    caption: t("pages.academic.title"),
  };

  return (
    <MainLayout title={title}>
      <Page title={t("pages.academic.title")} titleKey="pages.academic.title" isWidthSmall={true}>
        <div data-key="pages.academic.text" dangerouslySetInnerHTML={{ __html: t("pages.academic.text") }}></div>
      </Page>
    </MainLayout>
  );
}

export default Academic;
