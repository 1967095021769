import { Title, ListDescription } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";

function ProgramInfo({ cost, degree, form, organization, term }) {
  const { t } = useTranslation();

  return (
    <div className="page__block page__block_border">
      <Title type="h2" data-key="program.details">
        {t("program.details")}
      </Title>
      <ListDescription>
        <ListDescription.Item title={t("program.details_organization")} data-key="program.details_organization" description={organization.length ? organization : "—"} />
        <ListDescription.Item title={t("program.details_form")} data-key="program.details_form" description={form.length ? form : "—"} />
        <ListDescription.Item title={t("program.details_degree")} data-key="program.details_degree" description={degree.length ? degree : "—"} />
        <ListDescription.Item title={t("program.details_term")} data-key="program.details_term" description={term.length ? term : "—"} />
        <ListDescription.Item title={t("program.details_cost")} data-key="program.details_cost" description={cost.length ? cost : "—"} />
      </ListDescription>
    </div>
  );
}

export default ProgramInfo;
