import axios from "axios";
import { VIDEOS, VIDEOS_LOADING } from "../constants/videos";

import { API_URL } from "../config/config";
import { SOURCE } from "../constants/global";

export function fetchVideos(locale) {
  return (dispatch) => {
    dispatch(videosAreLoading(true));

    const config = { params: { source: SOURCE, locale: locale } };

    axios
      .get(`${API_URL}videos`, config)
      .then((response) => {
        return response;
      })
      .then((response) => {
        dispatch(videosSuccess(response.data.data));
        dispatch(videosAreLoading(false));

        return response;
      })
      .catch((e) => console.log("error loading videos", e));
  };
}

function videosAreLoading(bool) {
  return {
    type: VIDEOS_LOADING,
    bool,
  };
}

function videosSuccess(data) {
  return {
    type: VIDEOS,
    data,
  };
}
