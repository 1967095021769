import MainLayout from "../layout";
import { LinkDecor } from "@vshp/ui-components";
import Page from "../page/Page";
import { useTranslation } from "react-i18next";

function AwardsAndAccreditations() {
  const { t } = useTranslation();

  let title = {
    caption: t("pages.awards_and_accreditations.title"),
  };

  return (
    <MainLayout title={title}>
      <Page title={t("pages.awards_and_accreditations.title")} titleKey="pages.awards_and_accreditations.title">
        <LinkDecor link="https://siil.ch/docs/8cad/EduQua_Certificate_21742_2022_en__2_.pdf">eduQua Certificate</LinkDecor>
        <LinkDecor link="https://siil.ch/docs/8d1a/Online_Learning_Stand-alone_audit_Swiss_International_Institute_Lausanne_certificate_2022.pdf">QS Five Stars Certificate</LinkDecor>
      </Page>
    </MainLayout>
  );
}

export default AwardsAndAccreditations;
