import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate, createSearchParams } from "react-router-dom";
import { Form, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@vshp/ui-components";
import { SearchIcon, CloseIcon } from "../utils/svg";
import { useTranslation } from "react-i18next";
import { languagePathUrl } from "../utils/languagePathUrl";
import { fetchProgramsForms, fetchProgramsCategories, fetchPrograms } from "../../actions/programs";
import i18n from "i18next";

import { FILTER_OPEN_CLASS, FILTER_SEARCH_OPEN_CLASS } from "../../constants/filter";

const ProgramsFilter = ({ slug, degreeId }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  let locale = i18n.language;
  const dispatch = useDispatch();
  const { programsDegrees, programsForms, programsCategories, programs } = useSelector((state) => state.programs);
  const { currentLanguage } = useSelector((state) => state.global);
  const [form] = Form.useForm();
  const { Search } = Input;
  const { Option } = Select;
  let filterActiveSelectCount = 0;

  const [stateFilterToggled, setStateFilterToggled] = useState(false);
  const [stateFilterSearchToggled, setStateFilterSearchToggled] = useState(false);

  const [stateBtnDisabled, setStateBtnDisabled] = useState(true);
  const [stateSubmitBtnDisabled, setStateSubmitBtnDisabled] = useState(true);

  const [stateSearchBtnMobActive, setStateSearchBtnMobActive] = useState(false);
  const [stateFilterBtnMobActive, setStateFilterBtnMobActive] = useState(false);

  const [filterBtnMobCount, setFilterBtnMobCount] = useState(0);

  useEffect(() => {
    dispatch(fetchProgramsForms(locale));
    dispatch(fetchProgramsCategories(locale));
  }, [dispatch, locale]);

  useEffect(() => {
    dispatch(
      fetchPrograms({
        degree_id: slug ? [`${degreeId}`] : searchParams.getAll("degree_id[]").filter((val) => val !== ""),
        form_id: searchParams.getAll("form_id[]").filter((val) => val !== ""),
        category_id: searchParams.getAll("category_id[]").filter((val) => val !== ""),
        search: searchParams.get("search"),
        locale: locale,
      })
    );
  }, [dispatch, degreeId, searchParams, locale]);

  useEffect(() => {
    const isFilledField = (val) => {
      filterActiveSelectCount += 1;
      !stateFilterBtnMobActive && setStateFilterBtnMobActive(true);
      stateBtnDisabled && setStateBtnDisabled(false);
      return val;
    };

    const getInitialFormValue = (name) => (name.length > 0 ? isFilledField(name) : [""]);

    if (searchParams.get("search")) {
      setStateSearchBtnMobActive(true);
      stateBtnDisabled && setStateBtnDisabled(false);
    }

    form.setFieldsValue({
      "degree_id[]": getInitialFormValue(slug ? [`${degreeId}`] : searchParams.getAll("degree_id[]")),
      "form_id[]": getInitialFormValue(searchParams.getAll("form_id[]")),
      "category_id[]": getInitialFormValue(searchParams.getAll("category_id[]")),
      search: searchParams.get("search"),
    });

    setFilterBtnMobCount(filterActiveSelectCount);
  }, [dispatch, searchParams, degreeId, form]);

  const changeURL = (config) => {
    navigate({
      pathname: `${languagePathUrl(currentLanguage)}/programs`,
      search: `?${createSearchParams(config)}`,
    });
  };

  const onFinish = (values) => {
    if (values.search === null) {
      values.search = "";
    }
    filterActiveSelectCount = 0;
    const filteredValues = {
      degree_id: values.degree_id && values.degree_id.filter((val) => val !== ""),
      form_id: values.form_id && values.form_id.filter((val) => val !== ""),
      category_id: values.category_id && values.category_id.filter((val) => val !== ""),
    };

    for (var key in filteredValues) {
      filteredValues[key] && filteredValues[key].length > 0 && (filterActiveSelectCount += 1);
    }

    filterActiveSelectCount === 0 ? setStateFilterBtnMobActive(false) : setFilterBtnMobCount(filterActiveSelectCount);

    changeURL(values);
  };

  const onSearch = () => {
    form.submit();
  };

  const onChangeSearch = async ({ target: { value } }) => {
    stateSubmitBtnDisabled && setStateSubmitBtnDisabled(false);
  };

  const onValuesChange = ({ search }) => {
    if (search || search === "") {
      return;
    }
    stateBtnDisabled && setStateBtnDisabled(false);
    stateSubmitBtnDisabled && setStateSubmitBtnDisabled(false);
    form.submit();
  };

  const onReset = () => {
    !stateBtnDisabled && setStateBtnDisabled(true);
    !stateSubmitBtnDisabled && setStateSubmitBtnDisabled(true);
    stateSearchBtnMobActive && setStateSearchBtnMobActive(false);
    stateFilterBtnMobActive && setStateFilterBtnMobActive(false);
    setFilterBtnMobCount(0);
    navigate({
      pathname: `${languagePathUrl(currentLanguage)}/programs`,
    });
  };

  const openFilter = () => {
    setStateFilterToggled(!stateFilterToggled);
    !stateFilterToggled && document.body.classList.add(FILTER_OPEN_CLASS);
  };

  const openFilterSearch = () => {
    setStateFilterSearchToggled(!stateFilterSearchToggled);
    !stateFilterSearchToggled && document.body.classList.add(FILTER_OPEN_CLASS, FILTER_SEARCH_OPEN_CLASS);
  };

  const closeFilter = () => {
    setStateFilterToggled(false);
    setStateFilterSearchToggled(false);
    (stateFilterToggled || stateFilterSearchToggled) && document.body.classList.remove(FILTER_OPEN_CLASS, FILTER_SEARCH_OPEN_CLASS);
  };

  const onChangeSelect = (value, name) => {
    value.length > 1 &&
      value[0] === "" &&
      form.setFieldsValue({
        [name]: value.slice(1),
      });
  };

  const onSelect = (value, name) => {
    value === "" &&
      form.setFieldsValue({
        [name]: [""],
      });
  };

  const renderOption = (el) => (
    <Option key={el.id} value={el.id}>
      <span className="filter-option__wrap">
        <span className="filter-option__text">{el.attributes.title}</span>
        <span className="filter-option__count">({el.attributes["programs-count"]})</span>
      </span>
    </Option>
  );

  const renderSelect = (name, defaultOption, data) => (
    <Form.Item className="form__item filter__item form__item_select" name={name}>
      <Select
        mode="multiple"
        dropdownClassName="filter__select-dropdown"
        placeholder={t("ui.not_specified")}
        showArrow={true}
        maxTagCount={maxTagCount(name)}
        maxTagPlaceholder={maxTagPlaceholder}
        onChange={(value) => {
          onChangeSelect(value, name);
        }}
        onSelect={(value) => {
          onSelect(value, name);
        }}
        data-key-placeholder="ui.not_specified"
      >
        <Option value="">{defaultOption}</Option>
        {data.map((type) => renderOption(type))}
      </Select>
    </Form.Item>
  );

  const maxTagCount = (name) => form.getFieldValue(name) && form.getFieldValue(name).length > 1 && 0;
  const maxTagPlaceholder = () => {
    return (
      <div className="filter-option__wrap">
        <span className="filter-option__text" data-key="programs.filter_several_selected">
          {t("programs.filter_several_selected")}
        </span>
      </div>
    );
  };

  return (
    <div className="filter__wrap">
      <div className="filter-nav">
        <Button className={`filter__btn filter-nav__btn${stateSearchBtnMobActive ? " active" : ""}`} onClick={openFilterSearch} data-key="programs.search">
          {t("programs.search")}
          <span className="filter-nav__count">1</span>
        </Button>
        <Button className={`filter__btn filter-nav__btn${stateFilterBtnMobActive ? " active" : ""}`} onClick={openFilter} data-key="programs.filters">
          {t("programs.filters")}
          <span className="filter-nav__count">{filterBtnMobCount}</span>
        </Button>
      </div>

      <div className="filter-shown" data-key="programs.total.count">
        {t("programs.total.count", { count: programs.length })}
      </div>
      <div className="filter__backdrop" onClick={closeFilter}></div>

      <Form colon={false} onFinish={onFinish} autoComplete="off" name="filter" onReset={onReset} form={form} onValuesChange={onValuesChange} className="form filter">
        <div className="filter__header">
          <span className="filter__header-title" data-key="programs.filters">
            {t("programs.filters")}
          </span>
          <span className="filter__header-title filter__header-search" data-key="programs.search">
            {t("programs.search")}
          </span>
          <Button className="filter__close" size="small" corners="square" icon={true} onClick={closeFilter}>
            <CloseIcon />
          </Button>
        </div>

        <div className="filter__body">
          <div className="filter__row filter__search">
            <div className="form__label-title" data-key="programs.search">
              {t("programs.search")}
            </div>
            <Form.Item name="search">
              <Search
                placeholder={t("programs.search_placeholder")}
                value=""
                className="form__search-field"
                allowClear
                onChange={onChangeSearch}
                onSearch={onSearch}
                loading={false}
                enterButton={<SearchIcon />}
                data-key-placeholder="programs.search_placeholder"
              />
            </Form.Item>
          </div>

          {programsDegrees.length > 1 && (
            <div className="filter__row">
              <div className="form__label-title" data-key="programs.degree">
                {t("programs.degree")}
              </div>
              {renderSelect("degree_id[]", `${t("programs.degree_all")}`, programsDegrees)}
            </div>
          )}
          {programsForms.length > 1 && (
            <div className="filter__row">
              <div className="form__label-title" data-key="programs.educational_forms">
                {t("programs.educational_forms")}
              </div>
              {renderSelect("form_id[]", `${t("programs.educational_forms_all")}`, programsForms)}
            </div>
          )}
          {programsCategories.length > 1 && (
            <div className="filter__row">
              <div className="form__label-title" data-key="programs.common">
                {t("programs.common")}
              </div>
              {renderSelect("category_id[]", `${t("programs.common_all")}`, programsCategories)}
            </div>
          )}

          <div className="filter-reset filter__row disabled">
            <div className="filter-reset__text" data-key="programs.total.count">
              {t("programs.total.count", { count: programs.length })}
            </div>

            <Button className="filter-nav__btn" onClick={closeFilter} type="reset" disabled={stateBtnDisabled} data-key="programs.filter_reset">
              {t("programs.filter_reset")}
            </Button>
          </div>
        </div>
        <div className="filter-footer item filter__row">
          <div className="filter-footer__text" data-key="programs.total.count">
            {t("programs.total.count", { count: programs.length })}
          </div>
          <div className="filter-footer__nav">
            <Button className="filter__btn filter-nav__btn" onClick={closeFilter} type="reset" disabled={stateBtnDisabled} data-key="programs.filter_reset">
              {t("programs.filter_reset")}
            </Button>
            <Button className="filter__btn filter-nav__btn" onClick={closeFilter} disabled={stateSubmitBtnDisabled} data-key="programs.filter_apply">
              {t("programs.filter_apply")}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ProgramsFilter;
