import MainLayout from "../layout";
import Page from "../page/Page";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Section } from "@vshp/ui-components";
import { useSelector } from "react-redux";
import { languagePathUrl } from "../utils/languagePathUrl";

function ManagementFaculty() {
  const { currentLanguage } = useSelector((state) => state.global);
  const { t } = useTranslation();
  let title = {
    caption: t("pages.management_faculty.title"),
  };

  return (
    <MainLayout title={title}>
      <Page title={t("pages.management_faculty.title")} titleKey="pages.management_faculty.title" isWidthSmall={true}>
        <Section data-key="pages.management_faculty.text" dangerouslySetInnerHTML={{ __html: t("pages.management_faculty.text") }}></Section>
        <Section>
          <h3>
            <Link to={`${languagePathUrl(currentLanguage)}/programs/bakalavriat/239`}>Bachelor in Event Management</Link>
          </h3>
          <h3>
            <Link to={`${languagePathUrl(currentLanguage)}/programs/bakalavriat/243`}>Bachelor in Project Management</Link>
          </h3>
          <h3>
            <Link to={`${languagePathUrl(currentLanguage)}/programs/bakalavriat/241`}>Bachelor in Luxury Brand Management</Link>
          </h3>
          <h3>
            <Link to={`${languagePathUrl(currentLanguage)}/programs/magistratura/242`}>Master in International Relations</Link>
          </h3>

          <h3>
            <Link to={`${languagePathUrl(currentLanguage)}/programs/magistratura/237`}>Master of Business Administration EN</Link>
          </h3>
          <h3>
            <Link to={`${languagePathUrl(currentLanguage)}/programs/magistratura/244`}>Master of Business Administration RU</Link>
          </h3>
        </Section>
      </Page>
    </MainLayout>
  );
}

export default ManagementFaculty;
