import MainLayout from "../layout";
import Page from "../page/Page";
import { useTranslation } from "react-i18next";
import communityImg01 from "../../images/community_img_01.png";
import communityImg02 from "../../images/community_img_02.png";

function Community() {
  const { t } = useTranslation();
  let title = {
    caption: t("pages.community.title"),
  };

  return (
    <MainLayout title={title}>
      <Page title={t("pages.community.title")} titleKey="pages.community.title" isWidthSmall={true}>
        <div data-key="pages.community.text" dangerouslySetInnerHTML={{ __html: t("pages.community.text") }}></div>

        <figure>
          <img src={communityImg01} alt="" />
        </figure>
        <figure>
          <img src={communityImg02} alt="" />
        </figure>
      </Page>
    </MainLayout>
  );
}

export default Community;
