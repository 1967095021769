import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import _ from "lodash";
import { Loading } from "../utils/loading";
import { renderProgramCard } from "../utils/render-program-card";
import { fetchPrograms } from "../../actions/programs";
import { Title } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";
import { languagePathUrl } from "../utils/languagePathUrl";
import i18n from "i18next";

function ProgramsRecommended() {
  const dispatch = useDispatch();
  const { programs, programsAreLoading, programsDegreesAreLoading } = useSelector((state) => state.programs);
  const { currentLanguage } = useSelector((state) => state.global);
  let locale = i18n.language;

  useEffect(() => {
    dispatch(fetchPrograms({ locale: locale }));
  }, [dispatch, locale]);

  const randomProgramsRecommended = _.sampleSize(programs, 3);

  const { t } = useTranslation();

  return (
    <>
      <Title type="h2" data-key="home.heading.featured_courses">
        {t("home.heading.featured_courses")}
      </Title>
      <div className="programs-feed__wrapper">{programsAreLoading || programsDegreesAreLoading ? <Loading /> : renderProgramCard(randomProgramsRecommended)}</div>
      <div className="text-center">
        <Link className="btn btn_primary btn__more btn_max-width-sm" to={`${languagePathUrl(currentLanguage)}/programs`} data-key="home.all_courses">
          {t("home.all_courses")}
        </Link>
      </div>
    </>
  );
}

export default ProgramsRecommended;
