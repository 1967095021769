import axios from "axios";
import { CONTACTS, CONTACTS_LOADING, FEEDBACK_IS_SENDING, FEEDBACK_SUCCESS } from "../constants/contacts";
import { API_URL } from "../config/config";
import { SOURCE } from "../constants/global";

export function fetchContacts(locale) {
  return (dispatch) => {
    dispatch(contactsAreLoading(true));

    const config = { params: { source: SOURCE, locale: locale } };

    axios
      .get(`${API_URL}contacts`, config)
      .then((response) => {
        dispatch(contactsSuccess(response.data.data));
        dispatch(contactsAreLoading(false));
        return response;
      })
      .catch((e) => console.log("error loading organization contacts", e));
  };
}

function contactsAreLoading(bool) {
  return {
    type: CONTACTS_LOADING,
    bool,
  };
}

function contactsSuccess(data) {
  return {
    type: CONTACTS,
    data,
  };
}

export function sendFeedback(values) {
  return (dispatch, getState) => {
    dispatch(feedbackIsSending(true));
    dispatch(feedbackSuccess(false));

    const config = { ...values, source: SOURCE };

    axios
      .post(`${API_URL}contact_form`, config)
      .then((response) => {
        dispatch(feedbackIsSending(false));
        dispatch(feedbackSuccess(true));
      })
      .catch((e) => {
        console.log("error loading feedback", e);
      });
  };
}

function feedbackIsSending(bool) {
  return {
    type: FEEDBACK_IS_SENDING,
    bool,
  };
}

export function feedbackSuccess(bool) {
  return {
    type: FEEDBACK_SUCCESS,
    bool,
  };
}
