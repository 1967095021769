import { Title } from "@vshp/ui-components";

const PageText = ({ title, text }) => {
  return (
    <>
      {title !== null && title.length > 0 && <Title type="h2">{title}</Title>}
      <div dangerouslySetInnerHTML={{ __html: text }}></div>
    </>
  );
};

export default PageText;
