import { Card } from "@vshp/ui-components";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { languagePathUrl } from "../utils/languagePathUrl";

function ProgramCard(props) {
  const { programsDegrees } = useSelector((state) => state.programs);
  const { currentLanguage } = useSelector((state) => state.global);
  const { item, slug } = props;
  let degree;

  if (!slug) {
    degree = programsDegrees.filter((el) => parseInt(el.id, 10) === item.attributes["degree-id"]);
  }

  const planLink = `${slug ? slug : degree[0].attributes.slug}/${item.id}`;

  const programCardLink = ({ children, ...props }) => {
    return (
      <Link to={`${languagePathUrl(currentLanguage)}/programs/${planLink}`} {...props}>
        {children}
      </Link>
    );
  };

  const { t } = useTranslation();

  return (
    <Card
      className="programs-feed__item"
      btnText={t("ui.see_more")}
      cover={<img alt={item.attributes.title} src={item.attributes.photo.preview} />}
      title={item.attributes.title}
      link={programCardLink}
    >
      <Card.Row>
        <Card.Title data-key="program.degree">{t("program.degree")}</Card.Title>
        <Card.Subtitle>{item.attributes["degree-name"]}</Card.Subtitle>
      </Card.Row>
      {item.attributes.specialty != null && (
        <Card.Row>
          <Card.Title data-key="program.specialty">{t("program.specialty")}</Card.Title>
          <Card.Subtitle>{item.attributes.specialty}</Card.Subtitle>
        </Card.Row>
      )}
      <Card.Row>
        <Card.Title data-key="program.language">{t("program.language")}</Card.Title>
        <Card.Subtitle>{item.attributes["language-name"]}</Card.Subtitle>
      </Card.Row>
    </Card>
  );
}

export default ProgramCard;
