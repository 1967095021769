import MainLayout from "../layout";
import { LinkDecor } from "@vshp/ui-components";
import Page from "../page/Page";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

function StudyPlans() {
  const { t } = useTranslation();
  let locale = i18n.language;

  let title = {
    caption: t("pages.study_plans.title"),
  };

  return (
    <MainLayout title={title}>
      <Page title={t("pages.study_plans.title")} titleKey="pages.study_plans.title">
        {locale !== "ru" ? (
          <>
            <LinkDecor link="/docs/9012/Catalogue_of_Study_Programmes.pdf">Catalogue of Study Programmes</LinkDecor>
            <LinkDecor link="/docs/9026/Bachelor_of_Arts_in_Event_Management.docx.pdf">Bachelor of Arts in Event Management</LinkDecor>
            <LinkDecor link="/docs/9027/Bachelor_of_Arts_in_Luxury_Brand_Management.docx.pdf">Bachelor of Arts in Luxury Brand Management</LinkDecor>
            <LinkDecor link="/docs/9028/Bachelor_of_Arts_in_Project_Management.docx.pdf">Bachelor of Arts in Project Management</LinkDecor>
            <LinkDecor link="/docs/9029/Bachelor_of_Science_in_Information_Systems_and_Technology.docx.pdf">Bachelor of Science in Information Systems and Technology</LinkDecor>
            <LinkDecor link="/docs/902a/Bachelor_of_Science_in_Internet_Marketing.docx.pdf">Bachelor of Science in Internet Marketing</LinkDecor>
            <LinkDecor link="/docs/902b/Bachelor_of_Science_in_Web_Design.docx.pdf">Bachelor of Science in Web Design</LinkDecor>

            <LinkDecor link="/docs/902c/Master_of_Arts_in_International_Relations.docx.pdf">Master of Arts in International Relations</LinkDecor>

            <LinkDecor link="/docs/9025/Master_of_Business_Administration_EN.docx.pdf">Master of Business Administration EN</LinkDecor>
            <LinkDecor link="/docs/902d/Master_of_Business_Administration_RU.docx.pdf">Master of Business Administration RU</LinkDecor>
          </>
        ) : (
          <>
            <LinkDecor link="/docs/901d/Katalog_uchebnyh_programm.pdf">Каталог учебных программ</LinkDecor>
            <LinkDecor link="/docs/86b1/Uchebnyy_plan_po_programme_bakalavriata__Event-menedzhment_.pdf">Учебный план по программе Бакалавриата «Event-менеджмент»</LinkDecor>
            <LinkDecor link="/docs/86ad/Uchebnyy_plan_po_programme_bakalavriata__Brend-menedzhment_tovarov_i_uslug_klassa_lyuks_.pdf">
              Учебный план по программе Бакалавриата «Бренд-менеджмент товаров и услуг класса люкс»
            </LinkDecor>
            <LinkDecor link="/docs/8745/Uchebnyĭ_plan_po_programme_bakalavriata__Veb-dizaĭn_.pdf">Учебный план по программе бакалавриата «Веб-дизайн»</LinkDecor>
            <LinkDecor link="/docs/8744/Uchebnyĭ_plan_po_programme_bakalavriata__Internet-marketing_.pdf">Учебный план по программе Бакалавриата «Интернет-маркетинг»</LinkDecor>
            <LinkDecor link="/docs/86ae/Uchebnyy_plan_po_programme_bakalavriata__Informatsionnye_sistemy_i_tehnologii__.pdf">
              Учебный план по программе Бакалавриата «Информационные системы и технологии»
            </LinkDecor>
            <LinkDecor link="/docs/86b0/Uchebnyy_plan_po_programme_bakalavriata__Proektnyy_menedzhment_.pdf">Учебный план по программе Бакалавриата «Проектный менеджмент»</LinkDecor>
            <LinkDecor link="/docs/86aa/_Master_delovogo_administrirovaniya_v_upravlenii_biznesom__uchebnyy_plan_MVA.pdf">
              Учебный план по программе Магистратуры «Магистр делового администрирования в управлении бизнесом»
            </LinkDecor>
            <LinkDecor link="/docs/86b4/Uchebnyy_plan_po_programme_magistratury__Master_mezhdunarodnyh_otnosheniy_.pdf">
              Учебный план по программе Магистратуры «Магистр международных отношений»
            </LinkDecor>
          </>
        )}
      </Page>
    </MainLayout>
  );
}

export default StudyPlans;
