import MainLayout from "../layout";
import Page from "../page/Page";
import { useTranslation } from "react-i18next";
import { Collapse } from "@vshp/ui-components";
import organisationImg from "../../images/pages_institute_text_organisation.jpg";
import organisationImgRU from "../../images/pages_institute_text_organisation_ru.jpg";
import i18n from "i18next";

function Institute() {
  const { t } = useTranslation();
  let locale = i18n.language;

  let title = {
    caption: t("pages.institute.title"),
  };

  return (
    <MainLayout title={title}>
      <Page title={t("pages.institute.title")} titleKey="pages.institute.title" isWidthSmall={true}>
        <div data-key="pages.institute.text" dangerouslySetInnerHTML={{ __html: t("pages.institute.text") }}></div>

        <Collapse className="space_top">
          <Collapse.Item
            header={t("pages.institute.collapse_vision.title")}
            content={t("pages.institute.collapse_vision.content")}
            data-key-title="pages.institute.collapse_vision.title"
            data-key-content="pages.institute.collapse_vision.content"
          />
          <Collapse.Item
            header={t("pages.institute.collapse_history.title")}
            content={t("pages.institute.collapse_history.content")}
            data-key-title="pages.institute.collapse_history.title"
            data-key-content="pages.institute.collapse_history.content"
          />
        </Collapse>

        <div className="space_top" data-key="pages.institute.text_organisation" dangerouslySetInnerHTML={{ __html: t("pages.institute.text_organisation") }}></div>
        <figure>{locale !== "ru" ? <img src={organisationImg} alt="" /> : <img src={organisationImgRU} alt="" />}</figure>
      </Page>
    </MainLayout>
  );
}

export default Institute;
