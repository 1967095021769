import axios from "axios";
import { FAQ_HOME, FAQ_HOME_LOADING, FAQ, FAQ_LOADING } from "../constants/faq";

import { API_URL } from "../config/config";
import { SOURCE } from "../constants/global";

export function fetchFaq(locale) {
  return (dispatch) => {
    dispatch(faqIsLoading(true));

    const config = { params: { source: SOURCE, locale: locale } };

    axios
      .get(`${API_URL}faq`, config)
      .then((response) => {
        return response;
      })
      .then((response) => {
        dispatch(faqSuccess(response.data.data));
        dispatch(faqIsLoading(false));

        return response;
      })
      .catch((e) => console.log("error loading faq", e));
  };
}

function faqIsLoading(bool) {
  return {
    type: FAQ_LOADING,
    bool,
  };
}

function faqSuccess(data) {
  return {
    type: FAQ,
    data,
  };
}

export function fetchFaqHome(locale) {
  return (dispatch) => {
    dispatch(faqHomeAreLoading(true));

    const config = { params: { source: SOURCE, locale: locale } };

    axios
      .get(`${API_URL}faq/home`, config)
      .then((response) => {
        return response;
      })
      .then((response) => {
        dispatch(faqHomeSuccess(response.data.data));
        dispatch(faqHomeAreLoading(false));

        return response;
      })
      .catch((e) => console.log("error loading faq home", e));
  };
}

function faqHomeAreLoading(bool) {
  return {
    type: FAQ_HOME_LOADING,
    bool,
  };
}

function faqHomeSuccess(data) {
  return {
    type: FAQ_HOME,
    data,
  };
}
