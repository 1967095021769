import { combineReducers } from "redux";
import { TEACHERS, TEACHERS_LOADING } from "../constants/teachers";

export function teachers(state = [], action) {
  switch (action.type) {
    case TEACHERS:
      return action.data;
    default:
      return state;
  }
}

export function teachersAreLoading(state = true, action) {
  switch (action.type) {
    case TEACHERS_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export const teachersReducers = () =>
  combineReducers({
    teachers: teachers,
    teachersAreLoading: teachersAreLoading,
  });
