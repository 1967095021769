import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { fetchSettings } from "../../actions/settings";
import { fetchCurrentLanguage } from "../../actions/global";
import cn from "classnames";
import { LANGUAGES_OPEN_CLASS, DEFAULT_LANGUAGE } from "../../constants/global";
import { languagePathUrl } from "../utils/languagePathUrl";
import i18n from "i18next";

function HeaderLanguages() {
  const dispatch = useDispatch();
  const [isLanguagesToggled, toggleLanguages] = useState(false);
  const { settings, settingsAreLoading } = useSelector((state) => state.settings);
  let locale = i18n.language;
  let currentLanguageTitle;
  let location = useLocation();
  let path = locale === "en" ? location.pathname : location.pathname.substring(3);

  !settingsAreLoading && settings.locales.filter(([lng, title]) => title.toLowerCase() === locale && (currentLanguageTitle = title));

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    isLanguagesToggled ? document.body.classList.add(LANGUAGES_OPEN_CLASS) : document.body.classList.remove(LANGUAGES_OPEN_CLASS);
  }, [isLanguagesToggled]);

  useEffect(() => {
    dispatch(fetchSettings(locale));
    dispatch(fetchCurrentLanguage());
  }, [dispatch, locale]);

  const toToggleLanguages = () => toggleLanguages(!isLanguagesToggled);

  const handleChangeLanguageBtn = (item) => () => {
    changeLanguage(item);
    dispatch(fetchCurrentLanguage());
    toToggleLanguages();
  };

  const languagesItemClass = (current) =>
    cn("header__languages-item", {
      "header__languages-item_hide": current === i18n.language,
    });

  return (
    <>
      <div className="header__languages-backdrop" onClick={toToggleLanguages}></div>
      <div className="header__languages">
        <div className="header__languages-item header__languages-item_value" onClick={toToggleLanguages}>
          {currentLanguageTitle ? currentLanguageTitle : DEFAULT_LANGUAGE}
        </div>
        <div className="header__languages-wrapper">
          <div className="header__languages-item header__languages-item_active">{currentLanguageTitle ? currentLanguageTitle : DEFAULT_LANGUAGE}</div>
          {!settingsAreLoading &&
            settings.locales.map(([lng, title], id) => (
              <Link className={languagesItemClass(lng)} to={`${languagePathUrl(lng)}${path.length > 0 ? path : "/"}`} state={{ locale: lng }} key={id} onClick={handleChangeLanguageBtn(lng)}>
                {title}
              </Link>
            ))}
        </div>
      </div>
    </>
  );
}

export default HeaderLanguages;
