import { combineReducers } from "redux";
import { FAQ_HOME, FAQ_HOME_LOADING, FAQ, FAQ_LOADING } from "../constants/faq";

export function faq(state = [], action) {
  switch (action.type) {
    case FAQ:
      return action.data;
    default:
      return state;
  }
}

export function faqIsLoading(state = true, action) {
  switch (action.type) {
    case FAQ_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export function faqHome(state = [], action) {
  switch (action.type) {
    case FAQ_HOME:
      return action.data;
    default:
      return state;
  }
}

export function faqHomeAreLoading(state = true, action) {
  switch (action.type) {
    case FAQ_HOME_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export const faqReducers = () =>
  combineReducers({
    faq: faq,
    faqIsLoading: faqIsLoading,
    faqHome: faqHome,
    faqHomeAreLoading: faqHomeAreLoading,
  });
