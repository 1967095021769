import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import { fetchReviews, fetchReviewsLoadMore } from "../../actions/reviews";
import { Loading } from "../utils/loading";
import MainLayout from "../layout";
import ReviewCard from "./ReviewCard";
import { loadingIcon } from "../utils/svg";
import { Title, Button } from "@vshp/ui-components";
import reviewsBg from "../../images/page-reviews.jpg";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { URL } from "../../config/config";

function Reviews() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let content = null;
  let title = null;
  let locale = i18n.language;
  const { reviews, reviewsAreLoading, reviewsMoreAreLoading } = useSelector((state) => state.reviews);

  useEffect(() => {
    dispatch(fetchReviews(locale));
  }, [dispatch, locale]);

  if (reviewsAreLoading) {
    content = <Loading contentPreloader />;
    title = {
      caption: t("ui.loading"),
    };
    return <MainLayout title={title}>{content}</MainLayout>;
  }

  title = {
    caption: t("reviews.title"),
  };

  const handleLoadMore = () => {
    dispatch(fetchReviewsLoadMore(reviews.links.next));
  };

  return (
    <MainLayout title={title}>
      <div className="page__header">
        <img className="page__header-cover" src={reviewsBg} alt="" />
        <div className="page__header-body page__header-body_center">
          <Title decor={false} className="page__header-title" data-key="reviews.title">
            {t("reviews.title")}
          </Title>
          <div className="page__header-intro" data-key="reviews.intro">{t("reviews.intro")}</div>
          <a className="btn btn_primary page__header-btn" href={`${URL}/profile`} target="_blank" rel="noopener noreferrer" data-key="reviews.add_review">
            {t("reviews.add_review")}
          </a>
        </div>
      </div>
      <div className="page__blocks">
        <div className="reviews">
          {reviews.data.map((review, id) => (
            <ReviewCard review={review.attributes} key={id} />
          ))}
        </div>
        {reviews.links.next && (
          <div className="page__block text-center">
            <Button maxWidth={true} onClick={handleLoadMore} data-key="ui.load_more">
              {reviewsMoreAreLoading ? <Spin indicator={loadingIcon} /> : t("ui.load_more")}
            </Button>
          </div>
        )}
      </div>
    </MainLayout>
  );
}

export default Reviews;
