import { combineReducers } from "redux";
import { FACULTIES, FACULTIES_ARE_LOADING } from "../constants/faculties";

export function faculties(state = [], action) {
  switch (action.type) {
    case FACULTIES:
      return action.data;
    default:
      return state;
  }
}

export function facultiesAreLoading(state = true, action) {
  switch (action.type) {
    case FACULTIES_ARE_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export const facultiesReducers = () =>
  combineReducers({
    faculties: faculties,
    facultiesAreLoading: facultiesAreLoading,
  });
