import React, { useEffect } from "react";
import { MoonIcon, SunIcon } from "../utils/svg";
import { THEME_DARK_CLASS } from "../../constants/global";
import { getTheme, setTheme, removeTheme } from "../../actions/global";

const SwitchTheme = () => {
  let stateTheme = getTheme();

  useEffect(() => {
    if (stateTheme !== null) {
      document.body.classList.add(THEME_DARK_CLASS);
    } else {
      const classes = document.body.className.split(" ").filter((c) => !c.startsWith("theme"));
      document.body.className = classes.join(" ").trim();
    }
  }, []);

  const toggleSwitch = () => {
    if (document.body.matches(`.${THEME_DARK_CLASS}`)) {
      const classes = document.body.className.split(" ").filter((c) => !c.startsWith("theme"));
      document.body.className = classes.join(" ").trim();
      removeTheme();
    } else {
      document.body.classList.add(THEME_DARK_CLASS);
      setTheme();
    }
  };

  return (
    <button type="button" className="switch switch-theme" onClick={toggleSwitch}>
      <span className="switch__handle switch-theme__handle">
        <SunIcon />
        <MoonIcon />
      </span>
      <span className="switch-theme__item">
        <SunIcon />
      </span>
      <span className="switch-theme__item">
        <MoonIcon />
      </span>
    </button>
  );
};

export default SwitchTheme;
