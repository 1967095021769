import { combineReducers } from "redux";
import { SEARCH_RESULT, SEARCH_RESULT_ARE_LOADING } from "../constants/search";

export function searchResult(state = {}, action) {
  switch (action.type) {
    case SEARCH_RESULT:
      return action.data;
    default:
      return state;
  }
}

export function searchResultAreLoading(state = true, action) {
  switch (action.type) {
    case SEARCH_RESULT_ARE_LOADING:
      return action.bool;
    default:
      return state;
  }
}
export const searchResultReducers = () =>
  combineReducers({
    searchResult: searchResult,
    searchResultAreLoading: searchResultAreLoading,
  });
