import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useLocation } from "react-router-dom";
import { Image } from "antd";
import Slider from "../utils/Slider";
import { fetchProgram } from "../../actions/programs";
import { Loading } from "../utils/loading";
import MainLayout from "../layout";
import PageNotFound from "../PageNotFound";
import Teachers from "../shared/Teachers";
import Videos from "../shared/Videos";
import Partners from "../shared/Partners";
import Career from "../shared/Career";
import Installment from "../shared/Installment";
import ProgramInfo from "./ProgramInfo";
import ProgramsPopular from "./ProgramsPopular";
import Offer from "../shared/Offer";
import { URL } from "../../config/config";
import { Title, Button, ListOrdered, Section, LinkDecor } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";
import { isLoggedIn } from "../../actions/global";
import i18n from "i18next";
import { languagePathUrl } from "../utils/languagePathUrl";
import { COURSE_DEGREE_ID } from "../../constants/global";
import programDisciplinesBg from "../../images/disciplines.jpg";
import programConsultationBg from "../../images/consultation.jpg";

function ProgramPage() {
  const { id } = useParams();
  const loggedIn = isLoggedIn();
  let locale = i18n.language;
  const dispatch = useDispatch();
  let location = useLocation();
  const { currentLanguage } = useSelector((state) => state.global);
  const { program, programIsLoading, programIsLoaded, programErrorAvailableLocales } = useSelector((state) => state.programs);
  const { settings, settingsAreLoading } = useSelector((state) => state.settings);

  let path = locale === "en" ? location.pathname : location.pathname.substring(3);
  let content = null;
  let title = null;
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchProgram(id, locale));
  }, [dispatch, id, locale]);

  if (programIsLoading && programIsLoaded) {
    content = <Loading contentPreloader />;
    title = {
      caption: t("ui.loading"),
    };
    return <MainLayout title={title}>{content}</MainLayout>;
  }

  const handleChangeLanguage = (lng) => () => {
    i18n.changeLanguage(lng);
  };

  const renderLink = (length, lng, title, id) => (
    <Link to={`${languagePathUrl(lng)}${path.length > 0 ? path : "/"}`} key={id} onClick={handleChangeLanguage(lng)}>
      {title}
      {id < length - 1 ? ", " : ""}
    </Link>
  );

  if (!programIsLoaded) {
    if (programErrorAvailableLocales.length > 0) {
      let availableLocales = [];
      !settingsAreLoading &&
        settings.locales.length > 0 &&
        settings.locales.map(([lng, title]) => programErrorAvailableLocales.map((loc) => lng === loc && (availableLocales = [...availableLocales, [lng, title]])));

      const reason = "page.error_not_exist";
      const details = "page.error_not_exist_details";

      return <PageNotFound reason={reason} details={details} isNoTranslate={true} locales={availableLocales.map(([lng, title], id) => renderLink(availableLocales.length, lng, title, id))} />;
    }
    return <PageNotFound />;
  }

  const certificates = program.attributes.certificates;

  title = {
    caption: `Программа ${program.attributes.title}`,
  };

  return (
    <MainLayout title={title}>
      <div className="page__header">
        <img className="page__header-cover" src={program.attributes.photo.cover} alt={program.attributes.title} />
        <div className="page__header-body page__header-body_center page__header-body_theme">
          <Title decor={false} className="page__header-title">
            {program.attributes.title}
          </Title>
          {program.attributes.tagline && program.attributes.tagline.length > 0 && <div className="page__header-intro" dangerouslySetInnerHTML={{ __html: program.attributes.tagline }}></div>}
          <a
            className="btn btn_primary page__header-btn"
            href={`${URL}${program.attributes["course-exists"] ? `/plans/${program.attributes.slug}?refcid=${program.attributes["course-id"]}` : `/profile?refp=${program.attributes.title.replace(/ /g, "+")}`}`}
            data-key="program.join"
          >
            {t("program.join")}
          </a>
        </div>
      </div>
      <div className="page__blocks program__wrap">
        {program.attributes.description && program.attributes.description.length > 0 && (
          <div className="page__block page__block_border wysiwyg">
            <Title type="h2" data-key="program.description">
              {t("program.description")}
            </Title>
            <div dangerouslySetInnerHTML={{ __html: program.attributes.description }}></div>
          </div>
        )}

        {program.attributes.presentation && (
          <Section border={true}>
            <Title type="h2" data-key="program.presentation">
              {t("program.presentation")}
            </Title>
            <LinkDecor link={program.attributes.presentation.url}>{program.attributes.presentation.title}</LinkDecor>
          </Section>
        )}

        {program.attributes.documents && program.attributes.documents.length > 0 && (
          <Section border={true}>
            <Title type="h2" data-key="program.documents">
              {t("program.documents")}
            </Title>
            {program.attributes.documents.map(({ title, url }, id) => (
              <LinkDecor link={url} key={id}>
                {title}
              </LinkDecor>
            ))}
          </Section>
        )}

        {program.attributes.videos.data.length > 0 && <Videos videos={program.attributes.videos.data} />}

        {program.attributes.advantages.length > 0 && (
          <div className="page__block">
            <Title type="h2" data-key="program.advantages">
              {t("program.advantages")}
            </Title>
            <ListOrdered isTwoColumns={true}>
              {program.attributes.advantages.map((text, key) => (
                <ListOrdered.Item key={key}>{text}</ListOrdered.Item>
              ))}
            </ListOrdered>
          </div>
        )}

        {program.attributes.disciplines.length > 0 && (
          <div className="disciplines page__block page__block_cover text-center">
            <img className="page__block-cover" src={programDisciplinesBg} alt="" />
            <div className="page__block-body page__block-body_center">
              <Title type="h2" decor={false} data-key="program.disciplines">
                {t("program.disciplines")}
              </Title>
              <Slider className="slider-disciplines">
                {program.attributes.disciplines.map(({ title, content }, key) => (
                  <div className="slider__item" key={key}>
                    <div className="slider-disciplines__item">
                      <div className="slider-disciplines__name t-h3">{title}</div>
                      <div className="slider-disciplines__text wysiwyg">{content}</div>
                    </div>
                  </div>
                ))}
              </Slider>
              {program.attributes["plan-url"] !== null && (
                <Button className="page__block-btn" href={program.attributes["plan-url"]} target="_blank" rel="noopener noreferrer" data-key="program.disciplines.plan_download_button">
                  {t("program.disciplines.plan_download_button")}
                </Button>
              )}
            </div>
          </div>
        )}

        <div className="page__block page__block_cover page__block_cover_white text-center">
          <img className="page__block-cover" src={programConsultationBg} alt="" />
          <div className="page__block-body page__block-body_center">
            <Title type="h2" decor={false} data-key="program.consultation.title">
              {t("program.consultation.title")}
            </Title>
          </div>
          <div className="page__block-body_center page__block-body_small">
            <div className="page__block-text wysiwyg" dangerouslySetInnerHTML={{ __html: t("program.consultation.description") }}></div>
            {loggedIn ? (
              <Button href={`${URL}/profile`} target="_blank" rel="noopener noreferrer" data-key="program.consultation.ask_question">
                {t("program.consultation.ask_question")}
              </Button>
            ) : (
              <Button href={`${URL}/sign_in`} target="_blank" rel="noopener noreferrer" data-key="program.consultation.ask_question">
                {t("program.consultation.ask_question")}
              </Button>
            )}
          </div>
        </div>

        {certificates.length > 0 && (
          <div className="page__block">
            <Title type="h2" data-key="program.certificate_examples.title">
              {t("program.certificate_examples.title")}
            </Title>
            <div className="wysiwyg">
              {program.attributes["degree-id"] === COURSE_DEGREE_ID ? (
                <p data-key="program.course.certificate_examples.description">{t("program.course.certificate_examples.description", { program: `${program.attributes.title}` })}</p>
              ) : (
                <p data-key="program.certificate_examples.description">{t("program.certificate_examples.description", { program: `${program.attributes.title}` })}</p>
              )}
            </div>

            <div className="photos">
              <Image.PreviewGroup>
                {certificates.map((item, key) => (
                  <Image
                    key={key}
                    src={item.preview}
                    preview={{
                      src: item.origin,
                    }}
                  />
                ))}
              </Image.PreviewGroup>
            </div>
          </div>
        )}

        {program.attributes.teachers.data.length > 0 && <Teachers teachers={program.attributes.teachers.data} />}

        <div className={`page__block${program.attributes.career.salary !== null ? " page__block_border" : ""}`}>
          <Partners />
        </div>

        {program.attributes.career.salary !== null && <Career salary={program.attributes.career.salary} vacancies={program.attributes.career.vacancies} />}

        {currentLanguage === "ru" && <Installment />}
        <ProgramInfo
          cost={program.attributes["details-cost"]}
          degree={program.attributes["details-degree"]}
          form={program.attributes["details-form"]}
          organization={program.attributes["details-organization"]}
          term={program.attributes["details-term"]}
        />
        <ProgramsPopular />
        <Offer />
      </div>
    </MainLayout>
  );
}

export default ProgramPage;
