import axios from "axios";
import { REVIEWS, REVIEWS_LOADING, REVIEWS_HOME, REVIEWS_HOME_LOADING, REVIEWS_LOAD_MORE, REVIEWS_LOAD_MORE_LOADING } from "../constants/reviews";

import { API_URL } from "../config/config";
import { SOURCE } from "../constants/global";

export function fetchReviews(locale) {
  return (dispatch) => {
    dispatch(reviewsAreLoading(true));

    const config = { params: { source: SOURCE, locale: locale } };

    axios
      .get(`${API_URL}reviews`, config)
      .then((response) => {
        return response;
      })
      .then((response) => {
        dispatch(reviewsSuccess(response.data));
        dispatch(reviewsAreLoading(false));

        return response;
      })
      .catch((e) => console.log("error loading reviews", e));
  };
}

function reviewsAreLoading(bool) {
  return {
    type: REVIEWS_LOADING,
    bool,
  };
}

function reviewsSuccess(data) {
  return {
    type: REVIEWS,
    data,
  };
}

export function fetchReviewsLoadMore(url) {
  return (dispatch) => {
    dispatch(reviewsLoadMoreAreLoading(true));

    axios
      .get(`${url}`)
      .then((response) => {
        return response;
      })
      .then((response) => {
        dispatch(reviewsLoadMoreSuccess(response.data));
        dispatch(reviewsLoadMoreAreLoading(false));

        return response;
      })
      .catch((e) => console.log("error loading more reviews", e));
  };
}

function reviewsLoadMoreAreLoading(bool) {
  return {
    type: REVIEWS_LOAD_MORE_LOADING,
    bool,
  };
}

function reviewsLoadMoreSuccess(data) {
  return {
    type: REVIEWS_LOAD_MORE,
    data,
  };
}

export function fetchReviewsHome(locale) {
  return (dispatch) => {
    dispatch(reviewsHomeAreLoading(true));

    const config = { params: { source: SOURCE, locale: locale } };

    axios
      .get(`${API_URL}reviews/home`, config)
      .then((response) => {
        return response;
      })
      .then((response) => {
        dispatch(reviewsHomeSuccess(response.data.data));
        dispatch(reviewsHomeAreLoading(false));

        return response;
      })
      .catch((e) => console.log("error loading reviews home", e));
  };
}

function reviewsHomeAreLoading(bool) {
  return {
    type: REVIEWS_HOME_LOADING,
    bool,
  };
}

function reviewsHomeSuccess(data) {
  return {
    type: REVIEWS_HOME,
    data,
  };
}
