import { combineReducers } from "redux";
import { PRESSKIT, PRESSKIT_LOADING } from "../constants/presskit";

export function presskit(state = [], action) {
  switch (action.type) {
    case PRESSKIT:
      return action.data;
    default:
      return state;
  }
}

export function presskitAreLoading(state = true, action) {
  switch (action.type) {
    case PRESSKIT_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export const presskitReducers = () =>
  combineReducers({
    presskit: presskit,
    presskitAreLoading: presskitAreLoading,
  });
