import { combineReducers } from "redux";
import { DOCUMENTS, DOCUMENTS_LOADING } from "../constants/documents";

export function documents(state = [], action) {
  switch (action.type) {
    case DOCUMENTS:
      return action.data;
    default:
      return state;
  }
}

export function documentsAreLoading(state = true, action) {
  switch (action.type) {
    case DOCUMENTS_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export const documentsReducers = () =>
  combineReducers({
    documents: documents,
    documentsAreLoading: documentsAreLoading,
  });
