import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchAlumni } from "../../actions/alumni";
import i18n from "i18next";

const Alumni = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { alumni, alumniAreLoading } = useSelector((state) => state.alumni);
  let locale = i18n.language;

  useEffect(() => {
    dispatch(fetchAlumni(locale));
  }, [dispatch, locale]);

  return !alumniAreLoading && alumni.length > 0 ? (
    <div className="page__block-body page__block-body_center">
      <div className="alumni">
        {alumni.map((item, id) => (
          <div className="alumni__item" key={id}>
            <div className="alumni__item-photo">
              <img src={item.attributes.photo} alt={item.attributes.name} />
            </div>
            <div className="alumni__item-body">
              <div className="alumni__item-status">{t(item.attributes.state)}</div>
              <div className="alumni__item-title">{item.attributes.name}</div>
              <div className="alumni__item-subtitle">{item.attributes.email}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Alumni;
