import axios from "axios";
import { getToken, removeToken } from "./global";

axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const token = getToken();

    if (token) {
      config.headers["authorization"] = `JWT ${token}`;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    const originalRequest = error.config;
    const token = getToken();
    if (token && error.response.status === 401 && !originalRequest._retry) {
      removeToken();
      originalRequest._retry = true;
      axios.defaults.headers.common["authorization"] = ``;
      return axios(originalRequest);
    }

    return Promise.reject(error);
  }
);

export default axios;
