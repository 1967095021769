import { combineReducers } from "redux";
import { ALUMNI_NETWORK_SENDING, ALUMNI_NETWORK_SUCCESS, ALUMNI, ALUMNI_LOADING, ALUMNI_NETWORK_EXIT_SENDING } from "../constants/alumni";

export function alumniNetworkSending(state = true, action) {
  switch (action.type) {
    case ALUMNI_NETWORK_SENDING:
      return action.bool;
    default:
      return state;
  }
}

export function alumniNetworkData(state = {}, action) {
  switch (action.type) {
    case ALUMNI_NETWORK_SUCCESS:
      return action.data;
    default:
      return state;
  }
}

export function alumniNetworkExitSending(state = true, action) {
  switch (action.type) {
    case ALUMNI_NETWORK_EXIT_SENDING:
      return action.bool;
    default:
      return state;
  }
}

export function alumni(state = [], action) {
  switch (action.type) {
    case ALUMNI:
      return action.data;
    default:
      return state;
  }
}

export function alumniAreLoading(state = true, action) {
  switch (action.type) {
    case ALUMNI_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export const alumniNetworkReducers = () =>
  combineReducers({
    alumniNetworkSending: alumniNetworkSending,
    alumniNetworkData: alumniNetworkData,
    alumni: alumni,
    alumniAreLoading: alumniAreLoading,
    alumniNetworkExitSending: alumniNetworkExitSending,
  });
