import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { languagePathUrl } from "../utils/languagePathUrl";

function PartnerItem({ partners }) {
  const { currentLanguage } = useSelector((state) => state.global);

  return (
    <div className="partners">
      {partners.length > 0 &&
        partners.map((partner, key) => (
          <Link className="partners__item" to={`${languagePathUrl(currentLanguage)}/partners/${partner.attributes.slug}`} key={key}>
            <img src={partner.attributes.logo} alt={partner.attributes.title} />
          </Link>
        ))}
    </div>
  );
}

export default PartnerItem;
