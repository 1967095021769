import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainLayout from "../layout";
import { Title, LinkDecor, Section } from "@vshp/ui-components";
import aboutBg from "../../images/page_hero.jpg";
import { useTranslation } from "react-i18next";
import { renderProgramCard } from "../utils/render-program-card";
import { Loading } from "../utils/loading";
import { fetchFaculties } from "../../actions/faculties";
import i18n from "i18next";

const Faculties = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { faculties, facultiesAreLoading } = useSelector((state) => state.faculties);
  const { programsDegreesAreLoading } = useSelector((state) => state.programs);
  let locale = i18n.language;

  let title = {
    caption: t("pages.faculties.title"),
  };

  useEffect(() => {
    dispatch(fetchFaculties(locale));
  }, [dispatch, locale]);

  return (
    <MainLayout title={title}>
      <div className="page__header">
        <img className="page__header-cover" src={aboutBg} alt={t("pages.faculties.title")} />
        <div className="page__header-body page__header-body_center">
          <Title decor={false} className="page__header-title" data-key="pages.faculties.title">
            {t("pages.faculties.title")}
          </Title>
        </div>
      </div>
      <div className="page__blocks">
        <Section textSizeLg={true} border={faculties.length > 0}>
          <Section.BodySmall>
            <div data-key="pages.faculties.intro" dangerouslySetInnerHTML={{ __html: t("pages.faculties.intro") }}></div>
          </Section.BodySmall>
        </Section>

        {facultiesAreLoading ? (
          <div className="page__block">
            <Loading />
          </div>
        ) : (
          faculties.length > 0 &&
          faculties.map((faculty, id) => (
            <Section key={id} border={id !== faculties.length - 1}>
              <Title>{faculty.attributes.title}</Title>
              {faculty.attributes.description && (
                <Section.BodySmall className="wysiwyg">
                  <div dangerouslySetInnerHTML={{ __html: faculty.attributes.description }}></div>
                </Section.BodySmall>
              )}

              {faculty.attributes.documents.length > 0 &&
                faculty.attributes.documents.map((doc, key) => (
                  <LinkDecor key={key} link={doc.url}>
                    {doc.title}
                  </LinkDecor>
                ))}
              <div className="faculties__programs-feed programs-feed__wrapper">{programsDegreesAreLoading ? <Loading /> : renderProgramCard(faculty.attributes.programs.data)}</div>
            </Section>
          ))
        )}
      </div>
    </MainLayout>
  );
};

export default Faculties;
