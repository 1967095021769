import { Title } from "@vshp/ui-components";

const PageVideo = ({ title, video }) => {
  return (
    <>
      {title !== null && title.length > 0 && <Title type="h2">{title}</Title>}
      <div className="video" dangerouslySetInnerHTML={{ __html: video }}></div>
    </>
  );
};

export default PageVideo;
