import axios from "axios";
import { API_URL } from "../config/config";
import { ALUMNI_NETWORK_SUCCESS, ALUMNI_NETWORK_SENDING, ALUMNI, ALUMNI_LOADING, ALUMNI_NETWORK_EXIT_SENDING } from "../constants/alumni";
import { SOURCE } from "../constants/global";

export function joinAlumniNetwork(locale) {
  return (dispatch) => {
    dispatch(alumniNetworkSending(true));

    const config = { locale: locale, source: SOURCE };

    axios
      .post(`${API_URL}user/alumni_network`, config)
      .then((response) => {
        dispatch(alumniNetworkSending(false));
        dispatch(alumniNetworkData(response.data.data));
        return response;
      })
      .catch((e) => {
        console.log("error", e);
        dispatch(alumniNetworkSending(false));
      });
  };
}

function alumniNetworkData(data) {
  return {
    type: ALUMNI_NETWORK_SUCCESS,
    data,
  };
}

function alumniNetworkSending(bool) {
  return {
    type: ALUMNI_NETWORK_SENDING,
    bool,
  };
}

export function exitAlumniNetwork(locale) {
  return (dispatch) => {
    dispatch(alumniNetworkExitSending(true));

    const config = { params: { source: SOURCE, locale: locale } };

    axios
      .delete(`${API_URL}user/alumni_network`, config)
      .then((response) => {
        console.log(response);
        dispatch(alumniNetworkExitSending(false));
        dispatch(alumniNetworkData(response.data.data));
        return response;
      })
      .catch((e) => {
        console.log("error", e);
        dispatch(alumniNetworkExitSending(false));
      });
  };
}

function alumniNetworkExitSending(bool) {
  return {
    type: ALUMNI_NETWORK_EXIT_SENDING,
    bool,
  };
}

export function fetchAlumni(locale) {
  return (dispatch) => {
    dispatch(alumniAreLoading(true));

    const config = { params: { source: SOURCE, locale: locale } };

    axios
      .get(`${API_URL}user/alumni_network`, config)
      .then((response) => {
        return response;
      })
      .then((response) => {
        dispatch(alumni(response.data.data));
        dispatch(alumniAreLoading(false));

        return response;
      })
      .catch((e) => console.log("error loading documents", e));
  };
}

function alumniAreLoading(bool) {
  return {
    type: ALUMNI_LOADING,
    bool,
  };
}

function alumni(data) {
  return {
    type: ALUMNI,
    data,
  };
}
