import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainLayout from "../layout";
import MapContacts from "./MapContacts";
import { Title, Section } from "@vshp/ui-components";
import { MapIcon, PhoneIcon, MailIcon } from "../utils/svg";
import contactsBg from "../../images/page_hero.jpg";
import { formatPhone } from "../utils/formatPhone";
import { useTranslation } from "react-i18next";
import FormContacts from "./FormContacts";
import { fetchContacts } from "../../actions/contacts";
import i18n from "i18next";

function Contacts() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { contacts } = useSelector((state) => state.contacts);
  let locale = i18n.language;

  useEffect(() => {
    dispatch(fetchContacts(locale));
  }, [dispatch, locale]);

  let title = {
    caption: t("contacts.title"),
  };

  const regex = /(?<=\()[^)]+(?=\))/g;

  const formatPhoneForCall = (phone) => {
    let currentText = "";

    const textArrowArray = phone.match(regex);
    textArrowArray && textArrowArray.map((text) => !parseInt(text[0], 10) && (currentText = `(${text})`));

    return formatPhone(phone)
      .replace(currentText, "")
      .replace(/[^+\d]+/g, "");
  };

  return (
    <MainLayout title={title} global={false}>
      <div className="page__header">
        <img className="page__header-cover" src={contactsBg} alt={t("contacts.title")} />
        <div className="page__header-body page__header-body_center">
          <Title decor={false} className="page__header-title" data-key="contacts.title">
            {t("contacts.title")}
          </Title>
        </div>
      </div>
      <div className="page__blocks">
        <div className="contacts">
          {contacts.length > 0 &&
            contacts.map(({ attributes: { organization, address, phone, email, "map-lat": lat, "map-lon": lon, "map-zoom": zoom } }, index) => (
              <Section border={contacts.length === index + 1} key={index}>
                <h2 className="t-h3">{organization}</h2>

                <div className="contacts__block">
                  <div className="contacts__item contacts__item_medium">
                    <MapIcon />
                    <div className="contacts__item-label" data-key="contacts.address">
                      {t("contacts.address")}
                    </div>
                    <div className="contacts__item-value">{address}</div>
                  </div>

                  {phone && phone.length > 0 && (
                    <div className="contacts__item contacts__item_short">
                      <PhoneIcon />
                      <div className="contacts__item-label" data-key="contacts.phone">
                        {t("contacts.phone")}
                      </div>
                      <a href={`tel:${formatPhoneForCall(phone)}`} className="contacts__item-value">
                        {phone}
                      </a>
                    </div>
                  )}

                  {email && email.length > 0 && (
                    <div className="contacts__item contacts__item_short">
                      <MailIcon />
                      <div className="contacts__item-label" data-key="contacts.email">
                        {t("contacts.email")}
                      </div>
                      <a href={`mailto:${email}`} className="contacts__item-value">
                        {email}
                      </a>
                    </div>
                  )}
                </div>
              </Section>
            ))}

          <FormContacts />
          {contacts.length > 0 && <MapContacts />}
        </div>
      </div>
    </MainLayout>
  );
}

export default Contacts;
