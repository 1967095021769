import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { fetchTranslate } from "../../actions/settings";
import { fetchCurrentLanguage } from "../../actions/global";

import { CloseIcon, InfoIcon } from "../utils/svg";
import { useTranslation } from "react-i18next";
import { Button } from "@vshp/ui-components";
import i18next from "i18next";
import { setNoticeLocale } from "../../actions/global";
import { languagePathUrl } from "../utils/languagePathUrl";

function HeaderNotice({ lang }) {
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(true);

  const { t } = useTranslation();
  const { translate, translateLoading } = useSelector((state) => state.settings);
  const { currentLanguage } = useSelector((state) => state.global);

  const translationsKeys = "ui.locale_notice, ui.locale_notice_button";
  let location = useLocation();

  let path = location.pathname.replace(`${currentLanguage}/`, "");

  useEffect(() => {
    dispatch(fetchTranslate(translationsKeys, lang));
  }, [dispatch, lang]);

  i18next.addResourceBundle(lang, "translation", translate);

  const handleBtnClose = () => {
    setNoticeLocale();
    setOpen(false);
  };

  const changeLanguage = (lang) => () => {
    i18next.changeLanguage(lang);
    dispatch(fetchCurrentLanguage());
    handleBtnClose();
  };

  return (
    <>
      {isOpen && !translateLoading && (
        <div className="notice">
          <div className="notice__inner">
            <div className="notice__info-icon">
              <InfoIcon />
            </div>
            <div className="notice__content">
              <div className="notice__title" data-key="ui.locale_notice">{t("ui.locale_notice", { ns: "translation", lng: lang })}</div>
            </div>
            <Button className="notice__close" size="small" icon={true} corners="square" onClick={handleBtnClose}>
              <CloseIcon />
            </Button>
            <Link className="btn notice__action notice__action_white" data-key="ui.locale_notice_button" to={`${languagePathUrl(lang)}${path}`} onClick={changeLanguage(lang)}>
              {t("ui.locale_notice_button", { ns: "translation", lng: lang })}
            </Link>
          </div>
        </div>
      )}
    </>
  );
}

export default HeaderNotice;
