import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { setGetParam } from "../../actions/global";
import { fetchTeachers } from "../../actions/teachers";
import { fetchVideos } from "../../actions/videos";
import { URL } from "../../config/config";
import MainLayout from "../layout";
import About from "../shared/About";
import Mission from "../shared/Mission";
import Videos from "../shared/Videos";
import Levels from "../shared/Levels";
import Login from "../shared/Login";
import How from "../shared/How";
// import Partners from "../shared/Partners";
import Greeting from "../shared/Greeting";
import Features from "../shared/Features";
import Teachers from "../shared/Teachers";
import Steps from "../shared/Steps";
import Faq from "../shared/Faq";
import Reviews from "../shared/Reviews";
import ProgramsRecommended from "../shared/ProgramsRecommended";
import PostsRecent from "../shared/PostsRecent";
import Offer from "../shared/Offer";
import Accreditation from "../shared/Accreditation";
import { useTranslation } from "react-i18next";
import { Title } from "@vshp/ui-components";
import { TITLE } from "../../constants/title";
import { languagePathUrl } from "../utils/languagePathUrl";
import i18n from "i18next";

function Home() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let location = useLocation();
  let locale = i18n.language;
  const navigate = useNavigate();
  const { slug } = useParams();

  const { teachers, teachersAreLoading } = useSelector((state) => state.teachers);
  const { videos, videosAreLoading } = useSelector((state) => state.videos);

  useEffect(() => {
    if (location.pathname.startsWith(`${languagePathUrl(locale)}/r/`) && slug) {
      setGetParam("referral", slug);

      if (window.location.search.indexOf("utm_") > -1) {
        setGetParam("referer", window.location.href);
      }

      navigate({
        pathname: `${languagePathUrl(locale)}/`,
      });
    }
  }, []);

  useEffect(() => {
    dispatch(fetchTeachers(locale));
  }, [dispatch, locale]);

  useEffect(() => {
    dispatch(fetchVideos(locale));
  }, [dispatch, locale]);

  return (
    <MainLayout title={t(TITLE)}>
      <div className="main">
        <div className="page__header main__intro intro">
          <div className="intro__video">
            <video loop autoPlay muted playsInline preload="auto">
              <source src="/video/siil.ogv" type='video/ogg; codecs="theora, vorbis"' />
              <source src="/video/siil.webm" type='video/webm; codecs="vp8, vorbis"' />
              <source src="/video/siil.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
            </video>
          </div>
          <div className="page__header-body page__header-body_center">
            <Title decor={false} className="page__header-title main__intro-title" data-key="home.intro_title">
              {t("home.intro_title")}
            </Title>
            <div className="page__header-subtitle" data-key="home.intro_info" dangerouslySetInnerHTML={{ __html: t("home.intro_info") }}></div>
            <a className="btn btn_primary page__header-btn" href={`${URL}/sign_in`} data-key="home.intro_button">
              {t("home.intro_button")}
            </a>
          </div>
        </div>

        <div className="page__blocks">
          <About />
          <Mission />
          {!videosAreLoading && <Videos videos={videos} />}
          <Levels />
          <Login />
          <How />
          {/* <div className="page__block">
            <Partners />
          </div> */}
          <Greeting />
          <Accreditation />
          <Features />
          {!teachersAreLoading && <Teachers teachers={teachers} />}
          <Steps />
          <Faq />
          <Reviews />
          <div className="page__block">
            <ProgramsRecommended />
          </div>
          <Offer />
          <PostsRecent />
        </div>
      </div>
    </MainLayout>
  );
}

export default Home;
