import { combineReducers } from "redux";
import { USER_SESSION, USER_SESSION_LOADED, USER_SESSION_IS_LOADING, USER_CONSULT_INFO, REFERRALS, REFERRALS_ARE_LOADING } from "../constants/users";
import { IP_ADDRESS } from "../constants/global";

export function session(state = {}, action) {
  switch (action.type) {
    case USER_SESSION:
      return action.user;
    default:
      return state;
  }
}

export function sessionIsLoading(state = true, action) {
  switch (action.type) {
    case USER_SESSION_IS_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export function sessionLoaded(state = false, action) {
  switch (action.type) {
    case USER_SESSION_LOADED:
      return action.bool;
    default:
      return state;
  }
}

export function ipAddress(state = "", action) {
  switch (action.type) {
    case IP_ADDRESS:
      return action.value;
    default:
      return state;
  }
}

export function userConsult(state = {}, action) {
  switch (action.type) {
    case USER_CONSULT_INFO:
      return action.data;
    default:
      return state;
  }
}

export function referralsAreLoading(state = true, action) {
  switch (action.type) {
    case REFERRALS_ARE_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export function referrals(state = [], action) {
  switch (action.type) {
    case REFERRALS:
      return action.data;
    default:
      return state;
  }
}

export const usersReducers = () =>
  combineReducers({
    session: session,
    sessionIsLoading: sessionIsLoading,
    sessionLoaded: sessionLoaded,
    referrals: referrals,
    referralsAreLoading: referralsAreLoading,
    ipAddress: ipAddress,
    userConsult: userConsult,
  });
