import { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import XYZ from "ol/source/XYZ";
import { fromLonLat } from "ol/proj";
import Feature from "ol/Feature";
import { Icon, Style } from "ol/style";
import MultiPoint from "ol/geom/MultiPoint";
import { defaults as defaultControls } from "ol/control";

function MapContacts() {
  const { contacts } = useSelector((state) => state.contacts);
  const mapElement = useRef(null);
  const pointsArray = contacts.reduce((acc, item) => [...acc, fromLonLat([item.attributes["map-lon"], item.attributes["map-lat"]])], []);

  useEffect(() => {
    if (mapElement.current.children.length < 1) {
      const iconFeature = new Feature({
        geometry: new MultiPoint(pointsArray),
      });

      const iconStyle = new Style({
        image: new Icon({
          anchor: [0.5, 60],
          anchorXUnits: "fraction",
          anchorYUnits: "pixels",
          src: "/map_pin.svg",
        }),
      });

      iconFeature.setStyle(iconStyle);

      const initalFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [iconFeature],
        }),
      });

      const rasterLayer = new TileLayer({
        source: new XYZ({
          url: "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        }),
      });

      const map = new Map({
        target: mapElement.current,
        layers: [rasterLayer, initalFeaturesLayer],

        view: new View({
          zoom: contacts.length === 1 ? contacts[0].attributes["map-zoom"] : 14,
          center: contacts.length === 1 && pointsArray[0],
        }),
        controls: defaultControls(),
      });

      contacts.length > 1 && map.getView().fit(initalFeaturesLayer.getSource().getExtent(), { padding: [180, 120, 120, 120] });
    }
  });

  return <div ref={mapElement} className="contacts__map"></div>;
}

export default MapContacts;
