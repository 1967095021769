import MainLayout from "../layout";
import { LinkDecor } from "@vshp/ui-components";
import Page from "../page/Page";
import { useTranslation } from "react-i18next";

function StatutoryDocuments() {
  const { t } = useTranslation();

  let title = {
    caption: t("pages.statutory_documents.title"),
  };

  return (
    <MainLayout title={title}>
      <Page title={t("pages.statutory_documents.title")} titleKey="pages.statutory_documents.title">
        <LinkDecor link="/docs/8793/SIIL_IDE_CHE_142_924_376.pdf" data-key="pages.statutory_documents.link_title.confirming">
          {t("pages.statutory_documents.link_title.confirming")}
        </LinkDecor>
        <LinkDecor link="/docs/8c48/CHE142924376-11.03.2021-Merkmale.pdf" data-key="pages.statutory_documents.link_title.registry">
          {t("pages.statutory_documents.link_title.registry")}
        </LinkDecor>
        <LinkDecor link="/docs/88d4/SKM_C30821060718140.pdf" data-key="pages.statutory_documents.link_title.recognition">
          {t("pages.statutory_documents.link_title.recognition")}
        </LinkDecor>
      </Page>
    </MainLayout>
  );
}

export default StatutoryDocuments;
