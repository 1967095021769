import { Layout } from "antd";
import Header from "./Header";
import Footer from "./Footer";
import CookiesNotice from "./CookiesNotice";

import DocumentTitle from "../utils/document-title";
import { getNoticeCookies } from "../../actions/global";

const { Content } = Layout;

function MainLayout(props) {
  let noticeCookies = getNoticeCookies();

  return (
    <Layout>
      {props.title ? <DocumentTitle item={props.title} global={props.global} /> : null}
      <Header />
      <Content>
        <div className="content page">{props.children}</div>
      </Content>
      <Footer />
      {noticeCookies !== "yes" && <CookiesNotice />}
    </Layout>
  );
}

export default MainLayout;
