import MainLayout from "../layout";
import Page from "../page/Page";
import { useTranslation } from "react-i18next";
import { LinkDecor } from "@vshp/ui-components";

function AnnualReports() {
  const { t } = useTranslation();
  let title = {
    caption: t("pages.annual_reports.title"),
  };

  return (
    <MainLayout title={title}>
      <Page title={t("pages.annual_reports.title")} titleKey="pages.annual_reports.title" isWidthSmall={true}>
        <LinkDecor link="/docs/9018/Annual_report_2022.pdf">Annual report 2022</LinkDecor>
      </Page>
    </MainLayout>
  );
}

export default AnnualReports;
