import { useDispatch, useSelector } from "react-redux";
import { Form, Input } from "antd";
import MainLayout from "../layout";
import { Title, Button, Section } from "@vshp/ui-components";
import { DiplomasIcon } from "../utils/svg";
import diplomasBg from "../../images/diploma_hero.jpg";
import { checkDiplomasForm } from "../../actions/diplomas";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

function Diplomas() {
  const dispatch = useDispatch();
  const { checkDiplomas, checkDiplomasSending, checkDiplomasLoaded } = useSelector((state) => state.diplomas);
  const { t } = useTranslation();
  let locale = i18n.language;

  let title = {
    caption: t("pages.diploma.title"),
  };

  const onFinish = (values) => {
    dispatch(checkDiplomasForm(values, locale));
  };

  return (
    <MainLayout title={title}>
      <div className="page__header">
        <img className="page__header-cover" src={diplomasBg} alt={t("pages.diploma.title")} />
        <div className="page__header-body page__header-body_center">
          <Title decor={false} className="page__header-title" data-key="pages.diploma.title">
            {t("pages.diploma.title")}
          </Title>
          <div className="page__header-intro wysiwyg" data-key="pages.diploma.intro" dangerouslySetInnerHTML={{ __html: t("pages.diploma.intro") }}></div>

          <Form name="diplomas_path" className="documents__check-form form" initialValues="" onFinish={onFinish} autoComplete="off">
            <Form.Item name="number" rules={[{ required: true, message: t("pages.diploma.placeholder_put_number") }]} validateTrigger="onSubmit">
              <Input data-key-placeholder="diplomas.number" data-key-error="pages.diploma.placeholder_put_number" placeholder={t("diplomas.number")} prefix={<DiplomasIcon />} />
            </Form.Item>

            <Button type="submit" data-key="diplomas.button">
              {t("diplomas.button")}
            </Button>
          </Form>

          {checkDiplomasSending && !checkDiplomasLoaded && (
            <div className="documents__check-form-result wysiwyg" data-key="diplomas.not_found">
              {t("diplomas.not_found")}
            </div>
          )}
        </div>
      </div>
      <div className="page__blocks">
        {!checkDiplomasSending && (
          <Section>
            <Section.BodySmall className="wysiwyg">
              <div className="documents__check-form-result wysiwyg">
                <div className="documents__result-item">
                  <span className="documents__result-title" data-key="pages.diploma.number_title">
                    {t("pages.diploma.number_title")}:
                  </span>
                  <span>{checkDiplomas.attributes.number}</span>
                </div>
                <div className="documents__result-item">
                  <span className="documents__result-title" data-key="pages.diploma.name_title">
                    {t("pages.diploma.name_title")}:
                  </span>
                  <span>{checkDiplomas.attributes.user}</span>
                </div>
                {checkDiplomas.attributes.birthday && (
                  <div className="documents__result-item">
                    <span className="documents__result-title" data-key="pages.diploma.birthday_title">
                      {t("pages.diploma.birthday_title")}:
                    </span>
                    <span>{checkDiplomas.attributes.birthday}</span>
                  </div>
                )}
                {checkDiplomas.attributes.degree && (
                  <div className="documents__result-item">
                    <span className="documents__result-title" data-key="pages.diploma.degree_title">
                      {t("pages.diploma.degree_title")}:
                    </span>
                    <span dangerouslySetInnerHTML={{ __html: checkDiplomas.attributes.degree }}></span>
                  </div>
                )}
                {checkDiplomas.attributes.course && (
                  <div className="documents__result-item">
                    <span className="documents__result-title" data-key="pages.diploma.course_title">
                      {t("pages.diploma.course_title")}:
                    </span>
                    <span dangerouslySetInnerHTML={{ __html: checkDiplomas.attributes.course }}></span>
                  </div>
                )}
                {checkDiplomas.attributes["classifier-code"] && (
                  <div className="documents__result-item">
                    <span className="documents__result-title" data-key="pages.diploma.classifier_code_title">
                      {t("pages.diploma.classifier_code_title")}:
                    </span>
                    <span dangerouslySetInnerHTML={{ __html: checkDiplomas.attributes["classifier-code"] }}></span>
                  </div>
                )}
                {checkDiplomas.attributes["classifier-name"] && (
                  <div className="documents__result-item">
                    <span className="documents__result-title" data-key="pages.diploma.classifier_name_title">
                      {t("pages.diploma.classifier_name_title")}:
                    </span>
                    <span dangerouslySetInnerHTML={{ __html: checkDiplomas.attributes["classifier-name"] }}></span>
                  </div>
                )}
                {checkDiplomas.attributes.organization && (
                  <div className="documents__result-item">
                    <span className="documents__result-title" data-key="pages.diploma.organization_title">
                      {t("pages.diploma.organization_title")}:
                    </span>
                    <span dangerouslySetInnerHTML={{ __html: checkDiplomas.attributes.organization }}></span>
                  </div>
                )}
              </div>
            </Section.BodySmall>
          </Section>
        )}

        <Section>
          <Section.BodySmall className="wysiwyg">
            <div className="page__block-body_small wysiwyg" data-key="pages.diploma.text" dangerouslySetInnerHTML={{ __html: t("pages.diploma.text") }}></div>
          </Section.BodySmall>
        </Section>
      </div>
    </MainLayout>
  );
}

export default Diplomas;
